
import React from "react";
import ReactDOM from "react-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/logoMobilecopy.png";
import link from "../assets/link.png";
import suicide from "../assets/suicide.png";
import call from "../assets/call.png";
import text from "../assets/text.png";
import Chart from "react-google-charts";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { createBrowserHistory } from "history";
import Spinner from "react-bootstrap/Spinner";
import { Line } from "react-chartjs-2";
import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Component } from "react";
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
import {CircularProgress,Grid,InputAdornment,Snackbar,TextField,Typography, darkScrollbar,} from '@mui/material'
import Modal from "react-bootstrap/Modal";
import {PlayCircleRounded,PauseCircleRounded,Email,EmailOutlined,MarkEmailRead,} from '@mui/icons-material'
import { useGridScroll } from "@mui/x-data-grid/internals";
const history = createBrowserHistory({ forceRefresh: true });
var GAD7Data = [];
var PHQ9Data = [];
var GAD7Labels = [];
var PHQ9Labels = [];

export default function Home() {
  const [refresh, setRefresh] = useState(true);
  const [patientId, setpatientId] = useState(null);
  const [dashboardValues, setDashboardValues] = useState([]);
  const [submitting, setSubmitting] = useState(true);
  const [token, setToken] = useState(
    `${localStorage.getItem("dtt_PatientToken")}`
  );
  const patId = `${localStorage.getItem("dtt_PatientId")}`;
  const [gData, setGData] = useState([]);
  const [pData, setPData] = useState("");
  const [image, setImage] = useState("");
  const headers = {
    Authorization: `Bearer  ${localStorage.getItem("dtt_PatientToken")}`,
  };
  const [LineDataPHQ9, setLineDataPHQ9] = useState([]);
  const [LineDataGAD7, setLineDataGAD7] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const handleClose = () => {
    setModalVisible(false);
    history.push("/Home");
  };
  const [view, setView] = useState(false);
  const [gad7Rows, setGad7Rows] = useState(null)
  const [phq9Rows, setPhq9Rows] = useState(null)
  // const UnReadCell = ({ value, row }) => (
  //   <span
  //     style={{
  //       fontWeight: row.isRead ? 'normal' : 'bold',
  //       color: row.isRead ? 'inherit' : 'black',
  //     }}
  //   >
  //     {value}
  //   </span>
  // )


  const Gad7Columns = [
    // { field: 'id', headerName: 'ID', width: 50, renderCell: UnReadCell },
    {
      field: 'date',
      headerName: 'Assessment Date',
      width: 200,
      // renderCell: UnReadCell,
    },
    {
      field: 'totalScore',
       headerName: 'Total Score',
      width: 120,
    //  renderCell: UnReadCell,
    },
    {
      field: 'percentage',
      headerName: 'Percentage',
      width: 100,
      // renderCell: UnReadCell,
    },

  ]

  const Phq9Columns = [

    {
      field: 'date',
      headerName: 'Assessment Date',
      width: 200,
      // renderCell: UnReadCell,
      // cursor: 'pointer',
    },
    {
      field: 'totalScore',
       headerName: 'Total Score',
      width: 120,
    //  renderCell: UnReadCell,
    },
    {
      field: 'percentage',
      headerName: 'Percentage',
      width: 100,
      // renderCell: UnReadCell,
      // cursor: 'pointer',
    },
  
  
  
    // {
    //   field: 'source',
    //   headerName: 'Source',
    //   width: 120,
    //   renderCell: UnReadCell,
    //   cursor: 'pointer',
    // },
    // {
    //   field: 'isRead',
    //   headerName: 'Action',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   minWidth: 50,
    //   renderCell: (cellValues) => (
    //     <Button
    //       style={{
    //         width: '100%',
    //         color: 'red',
    //         height: '100%',
    //         // backgroundColor: 'green'
    //       }}
    //       onClick={(e) => {

    //         cellValues.row.isRead = false

    //         console.log("----------------", e)
    //         e.stopPropagation() // stop the event from propagating to the cell
    //         axios
    //           .get(
    //             `${process.env.REACT_APP_API_URL}/PatientPortal/MarkPatientPHQ9?id=${cellValues.id}&isRead=false`,
    //             {
    //               headers,
    //             }
    //           )
    //           .then((response) => {
    //             console.log(response.data)
    //             console.log('Button Clicked')
    //             // response.row.isRead = true
    //           })
    //           .catch((error) => {
    //             console.error(error)
    //           })
    //       }}
    //     >
    //       <MarkEmailRead style={{ color: 'red' }} />
    //     </Button>
    //   ),
    // },
  ]

  useEffect(() => {
    // if (_patientId !== undefined)
     {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/PatientPHQ9?PatientId=${parseInt(patId)}`,
          {
            headers,
          }
        )
        .then((response) => {
          console.log('response.data', response.data)
          setPhq9Rows(response.data)

          // setUnread(response.cellValues.isRead)

        })
        .catch((error) => {
          console.error('There was an error!', error)
        })

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/PatientGAD7?PatientId=${parseInt(patId)}`,
          {
            headers,
          }
        )
        .then((response) => {
          console.log('response.data', response.data)
          setGad7Rows(response.data)

          // setUnread(response.cellValues.isRead)

        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])

  useEffect(() => {
    // POST request using axios inside useEffect React hook

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/PatientPortal/getPatientDetailsById?pateintid=${parseInt(patId)}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setDashboardValues(response.data);
        setImage(response.data.photoUrl);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  useEffect(() => {
    setSubmitting(true);
  }, []);
  useEffect(() => {
    getGraphsDataPHQ9();
  }, [submitting]);
  useEffect(() => {
    getGraphsDataGAD7();
  }, [submitting]);

  const [dataloadedPHQ, setdataloadedPHQ] = useState(false);
  const [dataloadedGAD, setdataloadedGAD] = useState(false);
  async function getGraphsDataPHQ9() {
    // POST request using axios inside useEffect React hook
    const patId = `${localStorage.getItem("dtt_PatientId")}`;

    // console.log("submitting:", submitting);
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/PatientPortal/getPatientPHQ9?patientId=${parseInt(patId)}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        var data = response.data;
        // var phq9data = LineDataPHQ9;
        const LineDataPHQ9Temp = [
          [
            { type: "string" },
            { type: "number", label: "PHQ9 Score" },
            { type: "string", role: "style" },
          ],
        ];
        data.forEach((item) => {
          var dateStr = item.date;
          if (dateStr != null && dateStr.length > 0) {
            dateStr = dateStr.replace(" ", "\n");
            // dateStr = dateStr.split(" ")[0];
            /* if (Dimensions.get("window").width < "500") {
              var date = dateStr.split(" ")[0].split("/")[1];
              var month = dateStr.split(" ")[0].split("/")[0];
              dateStr = month + "/" + date;
            } */
            // var score = `${item.totalScore}`;
            var dataP = [];
            dataP.push(dateStr);
            dataP.push(parseInt(item.totalScore));
            if (item.totalScore < 4)
              dataP.push(
                "point { size: 7; shape-type: circle; fill-color: #389837; }"
              );
            else if (item.totalScore < 10)
              dataP.push(
                "point { size: 7; shape-type: circle; fill-color: #fbcf33  ; }"
              );
            else if (item.totalScore < 15)
              dataP.push(
                "point { size: 7; shape-type: circle; fill-color: #FFA07A  ; }"
              );
            else if (item.totalScore < 20)
              dataP.push(
                "point { size: 7; shape-type: circle; fill-color: #FF4500; }"
              );
            else if (item.totalScore < 28)
              dataP.push(
                "point { size: 7; shape-type: circle; fill-color: #DC143C; }"
              );
            else dataP.push(null);
            LineDataPHQ9Temp.push(dataP);
          }
          console.log("Data: ", LineDataPHQ9Temp);
        });
        setLineDataPHQ9(LineDataPHQ9Temp);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  async function getGraphsDataGAD7() {
    // POST request using axios inside useEffect React hook
    const patId = `${localStorage.getItem("dtt_PatientId")}`;
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/PatientPortal/getPatientGAD7?patientId=${parseInt(patId)}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        var data = response.data;
        const LineDataGAD7Temp = [
          [
            { type: "string" },
            { type: "number", label: "GAD7 Score" },
            { type: "string", role: "style" },
          ],
        ];
        if (data != null && data.length > 0) {
          data.forEach((item, i) => {
            var dateStr = item.date;
            if (dateStr != null && dateStr.length > 0) {
              dateStr = dateStr.replace(" ", "\n");
              // dateStr = dateStr.split(" ")[0];
              /*  if (Dimensions.get("window").width < "500") {
                var date = dateStr.split(" ")[0].split("/")[1];
                var month = dateStr.split(" ")[0].split("/")[0];
                dateStr = month + "/" + date;
              } */
              var dataG = [];
              dataG.push(dateStr);
              dataG.push(parseInt(item.totalScore));
              if (item.totalScore < 6)
                dataG.push(
                  "point { size: 7; shape-type: circle; fill-color: #389837; }"
                );
              else if (item.totalScore < 11)
                dataG.push(
                  "point { size: 7; shape-type: circle; fill-color: #fbcf33  ; }"
                );
              else if (item.totalScore < 16)
                dataG.push(
                  "point { size: 7; shape-type: circle; fill-color: #FFA07A  ; }"
                );
              else if (item.totalScore < 22)
                dataG.push(
                  "point { size: 7; shape-type: circle; fill-color: #FF4500; }"
                );
              else dataG.push("");
              LineDataGAD7Temp.push(dataG);
              // console.log("LineDataGAD7 Temp: ", LineDataGAD7Temp);
            }
          });

          setLineDataGAD7(LineDataGAD7Temp);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  useEffect(() => {
    // console.log("chart will be loaded:", LineDataGAD7);
    setdataloadedGAD(true);
  }, [LineDataGAD7]);
  useEffect(() => {
    // console.log("chart will be loaded");
    setdataloadedPHQ(true);
  }, [LineDataPHQ9]);

  const LineChartOptionsPHQ = {
    hAxis: {
      title: "Time",
    },
    lineWidth: 4,
    backgroundColor: "#F0FFFF", //"#73C2E3",
    legend: "none",
    annotations: { stemColor: "white", textStyle: { fontSize: 16 } },
    vAxis: {
      title: "Score",
      ticks: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28],
    },
    pointSize: 10,
    curveType: "function",
  };
  const LineChartOptionsGAD = {
    hAxis: {
      title: "Time",
    },
    lineWidth: 4,
    backgroundColor: "#F0FFFF", //"#73C2E3",
    legend: "none",
    annotations: { stemColor: "white", textStyle: { fontSize: 16 } },
    vAxis: {
      title: "Score",
      ticks: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
    },
    pointSize: 10,
    curveType: "function",
  };
  const PHQ9 = (event, id) => {
    history.push({
      pathname: `/PHQ`,
    });
  };
  const GAD7 = (event, id) => {
    history.push({
      pathname: `/GAD`,
    });
  };
  const JOURNAL = (event, id) => {
    history.push({
      pathname: `/Journal`,
    });
  };
  const LEARN = (event, id) => {
    history.push({
      pathname: `/LearningDocuments`,
    });
  };
  const CareTeam = (event, id) => {
    history.push({
      pathname: `/CareTeam`,
    });
  };
  const MEDITATE = (event, id) => {
    history.push({
      pathname: `/Meditate`,
    });
  };
  const gridRowStyle = {
    // boxShadow: 2,
    // border: 2,
    // // borderRadius: 5,
    // background: 'white',
    // fontSize: '13.6px !important',
    // color: '#8392ab !important',
    // borderColor: 'rgba(5, 152, 236, 0.637) !important',
    // '& .MuiDataGrid-cell:hover': {
    //   color: 'rgba(5, 152, 236, 0.637) !important',
    //   fontSize: '14.6px !important',
    // },

    "& ::-webkit-scrollbar": {
      width: "6px"
    },
    "& ::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5"
    },
    "& ::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
      backgroundColor: "#abdbe3"
    }
    
  }

 
  return (
    <>
      <Container style={{ maxWidth: "100%" }}>
        {/* Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop */}
        {/* <Row>
          <Col sm={4}></Col>
          <Col sm={4}>
            <img
              src={logo}
              style={{
                width: 250,
                height: 160,
                marginTop: 5,
                textAlign: "center",
              }}
            />
          </Col>
          <Col sm={4} style={{ fontWeight: "bold", textAlign: "right" }}>
            {dashboardValues.lastName != null ||
            dashboardValues.lastName != undefined ? (
              <label
                style={{
                  color: "black",
                  fontSize: 20,
                  fontWeight: "bold",
                  marginBottom: 5,
                }}
              >
                {`${dashboardValues.lastName},${dashboardValues.firstName}`}
              </label>
            ) : (
              <label>Loading! Please Wait.</label>
            )}
            <img
              src={`${process.env.REACT_APP_Documents_URL}/${encodeURI(image)}`}
              resizeMode="stretch"
              style={{
                width: "120px",
                height: "120px",
                margin: "5px",
                borderRadius: "100px",
                objectFit: "stretch",
              }}
            />
          </Col>
        </Row> */}
        <Row sm={12} xs={12} lg={12} md={12}>
          <Col sm={12} xs={12} lg={6} md={6}>
            <Row
              sm={12}
              xs={12}
              lg={12}
              md={12}
              // className="justify-content-md-center"
              style={{ marginTop: "5px" }}
            >
              <Col xs={0} sm={1} md={1} lg={2}></Col>
              <Col
                sm={10}
                xs={12}
                lg={8}
                md={10}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                <label style={{ fontWeight: "bold" }}>PHQ9</label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#389837",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  0-4
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#fbcf33",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  5-9
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#FFA07A",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  10-14
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#FF4500",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  15-19
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#DC143C",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  20-27
                </label>
              </Col>
              <Col xs={0} sm={1} md={1} lg={2}></Col>
            </Row>
            <Row>
              <Col style={{ marginLeft: "3px", marginBottom: "10px" }}>
                {dataloadedPHQ &&
                LineDataPHQ9 != null &&
                LineDataPHQ9.length > 1 ? (
                  <Chart
                    style={{ backgroundColor: "#73C2E3" }}
                    width={"100%"}
                    height={"410px"}
                    chartType="LineChart"
                    loader={
                      <Spinner
                        animation="border"
                        variant="warning"
                        style={{
                          width: "20px",
                          height: "20px",
                          // position: "absolute",
                          // float: 'right',
                          marginLeft: "40%",
                          marginTop: "40px",
                          zIndex: 1000,
                        }}
                      />
                    }
                    data={LineDataPHQ9}
                    options={LineChartOptionsPHQ}
                  />
                ) : (
                  <label>No Record found</label>
                )}
              </Col>
            </Row>
          </Col>




          
          <Col sm={12} xs={12} lg={6} md={6}>
            <Row sm={12} xs={12} lg={12} md={12} style={{ marginTop: "5px" }}>
              <Col xs={0} sm={1} md={1} lg={2}></Col>
              <Col
                sm={10}
                xs={12}
                lg={8}
                md={10}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                <label style={{ fontWeight: "bold", textAlign: "center" }}>
                  {" "}
                  GAD-7
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#389837",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "5px",
                    marginBottom: 10,
                  }}
                >
                  0-5
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#fbcf33",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "5px",
                    marginBottom: 10,
                  }}
                >
                  6-10
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#FFA07A",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "5px",
                    marginBottom: 10,
                  }}
                >
                  11-15
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#FF4500",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "5px",
                    marginBottom: 10,
                  }}
                >
                  16-21
                </label>
              </Col>
              <Col xs={0} sm={1} md={1} lg={2}></Col>
            </Row>
            <Row>
              <Col style={{ marginLeft: "3px", marginBottom: "10px" }}>
                {dataloadedGAD &&
                LineDataGAD7 != null &&
                LineDataGAD7.length > 1 ? (
                  <Chart
                    style={{ backgroundColor: "#73C2E3" }}
                    width={"100%"}
                    height={"410px"}
                    chartType="LineChart"
                    loader={
                      <Spinner
                        animation="border"
                        variant="warning"
                        style={{
                          width: "20px",
                          height: "20px",
                          // position: "absolute",
                          // float: 'right',
                          marginLeft: "40%",
                          marginTop: "40px",
                          zIndex: 1000,
                        }}
                      />
                    }
                    data={LineDataGAD7}
                    options={LineChartOptionsGAD}
                  />
                ) : (
                  <label>No Record found</label>
                )}
              </Col>
              {/* <Col style={{ marginLeft: "3px", marginBottom: "10px" }}>
                {dataloadedGAD &&
                LineDataGAD7 != null &&
                LineDataGAD7.length > 1 ? (
                  <Chart
                    width={"100%"}
                    height={"410px"}
                    chartType="LineChart"
                    loader={
                      <Spinner
                        animation="border"
                        variant="warning"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "40%",
                          // position: "absolute",
                          // left: "50%",
                          // top: "50%",
                          // marginLeft: "-25px",
                          marginTop: "40px",
                          zIndex: 1000,
                        }}
                      />
                    }
                    data={LineDataGAD7}
                    // data={[["Age", "Weight"], [4, 5.5], [8, 12]]}
                    options={LineChartOptions}
                  />
                ) : (
                  <label>No Record found</label>
                )}
              </Col> */}
            </Row>
          </Col>
        </Row>
        {/* <Grid item xs={2}>
        <Button
          style={{
            borderRadius: 5,
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#abdbe3',
            marginBottom: '10px',

          }}
          onClick={() => setView(true)}
        >
          More
          <ArrowForwardIosIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
        </Button>
      </Grid>

      {view && (
        <>
           <Grid
              container
              spacing={1}
              alignContent="center"
              justifyContent="center"
              style={{ paddingTop: '10px' }}
            >
          <Grid item xs={12}>
            <Button
              style={{
                borderRadius: 5,
                color: 'black',
                fontWeight: 'bold',
                backgroundColor: '#e3abad',
                marginBottom: '10px',
              }}
              onClick={() => setView(false)}
            >
              Less
              <ArrowBackIosIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Box mb={3} ml={16} style={{ width: '78%' }}>
              {phq9Rows.length === 0 ? null : (
                <div style={{ height: 400 }}>
                  <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>PHQ-9</Typography>
                  <DataGrid
                    rows={phq9Rows}
                    columns={Phq9Columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={gridRowStyle}
                  />
                </div>
              )}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={3} ml={1} style={{ width: '78%' }}>
              {gad7Rows.length === 0 ? null : (
                <div style={{ height: 400 }}>
                  <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>GAD-7</Typography>
                  <DataGrid
                    rows={gad7Rows}
                    columns={Gad7Columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    sx={gridRowStyle}
                  />
                </div>
              )}
          </Box>
          </Grid>
          </Grid>
        </>
      )} */}
       <Grid container spacing={2}  >
      {!view ? (
        <Grid item xs={2}>
          <Button
            style={{
              borderRadius: 5,
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#abdbe3',
              marginBottom: '10px',
            }}
            onClick={() => setView(true)}
          >
            More
            <ArrowForwardIosIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Button
            style={{
              borderRadius: 5,
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#abdbe3',
              marginBottom: '10px',
            }}
            onClick={() => setView(false)}
          >
            Less
            <ArrowBackIosIcon style={{ fontSize: '14px', marginLeft: '8px' }} />
          </Button>
        </Grid>
      )}

      {view && (
        <>
          <Grid container spacing={1} alignContent="center" justifyContent="center" style={{ paddingTop: '10px' }}>
            <Grid item xs={6}>
              <Box mb={3} ml={16} style={{ width: '78%' }}>
                {phq9Rows.length === 0 ? null : (
                  <div style={{ height: 400 }}>
                    <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>PHQ-9</Typography>
                    <DataGrid
                      rows={phq9Rows}
                      columns={Phq9Columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      sx={gridRowStyle}
                    />
                  </div>
                )}
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box mb={3} ml={1} style={{ width: '78%' }}>
                {gad7Rows.length === 0 ? null : (
                  <div style={{ height: 400 }}>
                    <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>GAD-7</Typography>
                    <DataGrid
                      rows={gad7Rows}
                      columns={Gad7Columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      sx={gridRowStyle}
                    />
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
        <Row sm={12} xs={12} lg={12} md={12}>
          <Col sm={0} xs={0} lg={3} md={1}></Col>
          <Col sm={12} xs={12} lg={6} md={10} style={{ textAlign: "center" }}>
            <Row>
              <Col
                sm={12}
                xs={12}
                lg={6}
                md={6}
                style={{ textAlign: "center" }}
              >
                <Button
                  type="submit"
                  style={{
                    borderRadius: 5,
                    marginTop: 15,
                    color: "black",
                    fontWeight: "bold",
                    height: "55px",
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#FCBE13",
                  }}
                  onClick={PHQ9}
                >
                  PHQ-9
                </Button>
              </Col>
              <Col sm={12} md={6} lg={6} xs={12}>
                <Button
                  type="submit"
                  style={{
                    borderRadius: 5,
                    marginTop: 15,
                    color: "black",
                    fontWeight: "bold",
                    height: "55px",
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#FCBE13",
                  }}
                  onClick={GAD7}
                >
                  GAD-7
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} lg={6} md={6}>
                <Button
                  type="submit"
                  style={{
                    borderRadius: 5,
                    marginTop: 10,
                    color: "black",
                    fontWeight: "bold",
                    height: "55px",
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#FCBE13",
                  }}
                  onClick={JOURNAL}
                >
                  JOURNAL
                </Button>
              </Col>
              <Col sm={12} xs={12} lg={6} md={6}>
                <Button
                  type="submit"
                  style={{
                    borderRadius: 5,
                    marginTop: 10,
                    color: "black",
                    fontWeight: "bold",
                    height: "55px",
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#FCBE13",
                  }}
                  onClick={LEARN}
                >
                  LEARN
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} lg={6} md={6}>
                <Button
                  type="submit"
                  style={{
                    borderRadius: 5,
                    marginTop: 10,
                    color: "black",
                    fontWeight: "bold",
                    height: "55px",
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#FCBE13",
                  }}
                  onClick={MEDITATE}
                >
                  MEDITATE
                </Button>
              </Col>
              <Col sm={12} xs={12} lg={6} md={6}>
                <Button
                  type="submit"
                  style={{
                    borderRadius: 5,
                    marginTop: 10,
                    color: "black",
                    fontWeight: "bold",
                    height: "55px",
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#FCBE13",
                  }}
                  onClick={CareTeam}
                >
                  Care Team
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm={0} xs={0} lg={3} md={1}></Col>
        </Row>
        <Row sm={12} xs={12} lg={12} md={12}>
          <Col sm={0} md={1} xs={0} lg={3}></Col>
          <Col sm={12} md={10} xs={12} lg={6} style={{ textAlign: "center" }}>
            <Row> 
               <Col lg={4} xs={12} md={4} sm={12} style={{ textAlign: "center" }}>
                <img
                  src={link}
                  style={{
                    width: 180,
                    height: 180,
                    marginTop: 5,
                    // marginLeft: "70px",
                    textAlign: "center",
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://suicidepreventionlifeline.org")
                  }
                />
              </Col>
              <Col lg={4} xs={12} md={4} sm={12} style={{ textAlign: "center" }}
              >
                  <img
                  src={text}
                  style={{
                    width: 180,
                    height: 160,
                    marginTop: 12,
                    cursor: 'pointer',
                    // marginLeft: "70px",
                    textAlign: "center",
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://www.crisistextline.org/")
                  }
                />
                 
              </Col> 
              <Col lg={4} xs={12} md={4} sm={12} style={{ textAlign: "center" }}
              >
                  <img
                  src={call}
                  style={{
                    width: 180,
                    height: 180,
                    cursor: 'pointer',
                    marginTop: 5,
                    // marginLeft: "70px",
                    textAlign: "center",
                  }}
                  onClick={() =>
                    (window.location.href =
                      "https://988lifeline.org/")
                  }
                />
                 
              </Col>
               
            </Row>
            <Row>
              <Button
                type="submit"
                style={{
                  borderRadius: 5,
                  marginTop: 10,
                  color: "black",
                  fontWeight: "bold",
                  height: "55px",
                  textAlign: "center",
                  width: "100%",
                  backgroundColor: "#2BA042",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://suicidepreventionlifeline.org")
                }>
                I'm having thoughts of hurting myself
              </Button>
              </Row>
          </Col>
          <Col sm={0}  md={1} xs={0} lg={3}  ></Col>
        </Row>
        
      </Container>
    </>
  );
}
