import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MeditationDocsCard from "../components/MeditationDocsCard";

// import { theme } from '../core/theme'
// import { process.env.REACT_APP_Patient_Documents } from '@env'
// import SelectDropdown from 'react-native-select-dropdown'

// import logo from "..";
export default function Meditate({ navigation }) {
  return (
    <Container >
      <Row xs={12} sm={12} md={12} lg={12} >
      <Col xs={0} sm={0} md={1} lg={1}></Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
            name={'Introduction to Meditation'}
            description={
              'These short sessions will explain what mindfulness and meditation are and talk you through whats needed to get ready to meditate. ⓘ You can also find these …'
            }
            mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a1.png`}
            file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Introduction to Meditation.html`}
           
          />
        </Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
             name={'Beginner Meditation Course'}
             description={
               'If you have had little or no meditation experience, these guided meditations will help you start a regular practice. Each session is about 10 minutes. Together, …'
             }
             mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a2.png`}
             file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Beginner Meditation Course.html`}
             
          />
        </Col>
              <Col xs={0} sm={0} md={1} lg={1}></Col>
      </Row>
      <Row xs={12} sm={12} md={12} lg={12} >
      <Col xs={0} sm={0} md={1} lg={1}></Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
            name={'Intermediate Meditation Course'}
            description={
              'These guided meditations are designed for you if you have already learned the basics of mindfulness meditation, and you would like to develop your practice further. … '
            }
            mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a3.png`}
            file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Intermediate Meditation Course.html`}
          />
        </Col>
         <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
            name={'Meditation for Stress & Anxiety'}
            description={
              'This pack is designed to help you manage stress and anxiety. Mindfulness meditation can help us deal with these negative emotions by transforming the way we …'
            }
            mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a4.png`}
            file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Meditation for Stress & Anxiety.html`}
            
          />
        </Col>
        <Col xs={0} sm={0} md={1} lg={1}></Col>
      </Row>
      <Row xs={12} sm={12} md={12} lg={12} >
      <Col xs={0} sm={0} md={1} lg={1}></Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
        name={'Meditation for Sleep'}
        description={
          'These guided sleep meditations will ease you into a peaceful and restful sleep. Good sleep is a cornerstone of wellbeing, helping us to maintain our physical …'
        }
        mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a5.png`}
        file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Meditation for Sleep.html`}
          
          />
        </Col>
         <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
            name={'Work Life Meditation Pack'}
            description={
              'Meditation and mindfulness can have a transformative effect on all aspects of our lives. This pack is specifically designed to help you lead a happier, …'
            }
            mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a6.png`}
            file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Work Life Meditation Pack.html`}
             
          />
        </Col>
        <Col xs={0} sm={0} md={1} lg={1}></Col>
      </Row>
      <Row xs={12} sm={12} md={12} lg={12} >
      <Col xs={0} sm={0} md={1} lg={1}></Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
          name={'Walking Meditation'}
          description={
            'You don not have to stay still to meditate. A walking meditation can be a great way to check in with your mind and body. It allows you to use different …'
          }
          mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a8.png`}
          file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Walking Meditation.html`}
          />
        </Col>
         <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
          name={'Meditating with Great Thinkers'}
          description={
            'Deepen your meditation practice by contemplating philosophical concepts from some of the greatest thinkers of all time. ⓘ You can also find these meditations, …'
          }
          mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a9.png`}
          file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Meditating with Great Thinkers.html`}
          />
        </Col>
        <Col xs={0} sm={0} md={1} lg={1}></Col>
      </Row>
      <Row xs={12} sm={12} md={12} lg={12} >
      <Col xs={0} sm={0} md={1} lg={1}></Col>
        <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
          name={'Body Scan Meditation'}
          description={
            'Body scan meditation involves focusing on bodily sensations, gradually and sequentially moving your attention through each part of the body. This type of …'
          }
          mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a10.png`}
          file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Body Scan Meditation.html`}
            
          />
        </Col>
         <Col xs={12} sm={12} md={5} lg={5}>
          <MeditationDocsCard
            name={'Mantra Meditation'}
            description={
              'Mantra meditation uses a word, a phrase or a sound as the main focus instead of the breath or bodily sensations. You can choose a mantra that resonates with …'
            }
            mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a11.png`}
            file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Mantra Meditation.html`}
          />
        </Col>
        <Col xs={0} sm={0} md={1} lg={1}></Col>
      </Row>
      <Row xs={12} sm={12} md={12} lg={12} >
      <Col xs={0} sm={0} md={3} lg={3}></Col>
        <Col xs={12} sm={12} md={6} lg={6}>
        <MeditationDocsCard
            name={'Loving Kindness Meditation'}
            description={
              'Loving Kindness (or "Metta") meditation, involves directing warmth and positivity to ourselves and to others. This type of meditation cultivates a more …'
            }
            mainImage={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/images/logo_a7.png`}
            file={` ${process.env.REACT_APP_Patient_Documents}/meditationResources/Mantra Meditation.html`}
           />
        </Col>
         
        <Col xs={0} sm={0} md={3} lg={3}></Col>
      </Row>
    </Container>
  );
}
