import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import logo from "../assets/logoMobilecopy.png";
import Background from "../assets/background@2x.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

export default function Login() {
  const [email, setEmail] = useState({ value: "", error: "" });
  const [passwordSet, setPasswordSet] = useState({ value: "", error: "" });
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userName = localStorage.getItem("UserName");
    const password = localStorage.getItem("Password");
    const redirect = localStorage.getItem("redirect");

    if (userName && password) {
      setEmail({ value: userName, error: "" });
      setPasswordSet({ value: password, error: "" });
      if (redirect === "phq9") {
        navigate("/PHQ");
        localStorage.removeItem("redirect");
      }
    }
  }, [navigate]);

  const validateForm = () => {
    const emailError = email.value ? "" : "Email is required.";
    const passwordError = passwordSet.value ? "" : "Password is required.";

    setEmail({ ...email, error: emailError });
    setPasswordSet({ ...passwordSet, error: passwordError });

    return !emailError && !passwordError;
  };

  const onLoginPressed = () => {
    if (!validateForm()) {
      return;
    }

    setSubmitting(true);

    const postData = {
      emailAddress: email.value,
      password: passwordSet.value,
    };
     axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/patientLogin`,
        postData
      )
      .then((response) => {
        if (response.data) {
          localStorage.setItem("patientuserEmail", response.data.emailAddress);
          localStorage.setItem("dtt_PatientToken", response.data.token);
          localStorage.setItem("username", response.data.userName);
          localStorage.setItem(
            "dtt_PatientId",
            response.data.patientId.toString()
          );
          navigate("/Home");
        } else {
          setSubmitting(false);
          alert("ss!! Wrong EmailAddress or Password");
        }
      })
      .catch((error) => {
        setSubmitting(false);
        alert(`Login failed: ${error.message}`);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onLoginPressed();
    }
  };

  return (
    <div
      id="backImgConver"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: "#1C1C1C",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <Container>
        {submitting && (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-25px",
              marginTop: "-25px",
              zIndex: 1000,
            }}
          />
        )}

        <Row>
          <center>
            <Col
              lg={4}
              md={8}
              sm={8}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <img
                src={logo}
                alt="logo"
                style={{ width: 200, height: 160, marginTop: 10 }}
                onClick={() => navigate("/")}
              />

              <Row style={{ textAlign: "left" }}>
                <label className="mb-3" style={{ color: "#FCBE13" }}>
                  Email
                </label>
                <Form.Control
                  className="mb-3"
                  type="email"
                  placeholder="Enter email"
                  style={{
                    backgroundColor: "#73C2E3",
                    border: "2px solid #fcbe13",
                    borderRadius: "10px",
                    height: "45px",
                  }}
                  value={email.value}
                  onChange={(e) =>
                    setEmail({ value: e.target.value, error: "" })
                  }
                  isInvalid={!!email.error}
                  onKeyPress={handleKeyPress}
                />
                <Form.Control.Feedback type="invalid">
                  {email.error}
                </Form.Control.Feedback>
                <label className="mb-3" style={{ color: "#FCBE13" }}>
                  Password
                </label>
                <Form.Control
                  className="mb-3"
                  type="password"
                  placeholder="Enter password"
                  style={{
                    backgroundColor: "#73C2E3",
                    border: "2px solid #fcbe13",
                    borderRadius: "10px",
                    height: "45px",
                  }}
                  value={passwordSet.value}
                  onChange={(e) =>
                    setPasswordSet({
                      value: e.target.value,
                      error: "",
                    })
                  }
                  isInvalid={!!passwordSet.error}
                  onKeyPress={handleKeyPress}
                />
                <Form.Control.Feedback type="invalid">
                  {passwordSet.error}
                </Form.Control.Feedback>

                <Button
                  type="submit"
                  style={{
                    borderRadius: 20,
                    marginTop: 10,
                    color: "black",
                    fontWeight: "bold",
                    height: "55px",
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "#FCBE13",
                  }}
                  onClick={onLoginPressed}
                  disabled={submitting}
                >
                  LOGIN
                </Button>
                <label
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontSize: "25px",
                    textAlign: "center",
                  }}
                >
                  "Alone we can do so little,
                  <br /> Together we can do so much"
                </label>
                <label style={{ color: "#FCBE13", textAlign: "right" }}>
                  Helen Keller
                </label>
              </Row>
            </Col>
          </center>
        </Row>
      </Container>
    </div>
  );
}
