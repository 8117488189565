import { useState } from "react";
import { Button } from "react-bootstrap";
import "../styles/navbar.css";
import { createBrowserHistory } from "history";
import logo from "../assets/logo.png";
import pic from "../assets/blue.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { passwordValidator } from "../helpers/passwordValidator";

export default function Navbar() {
  const history = createBrowserHistory({ forceRefresh: true });
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const logOutClickHandler = (e) => {
    e.preventDefault();
    localStorage.removeItem("dtt_PatientToken");
    localStorage.removeItem("dtt_PatientId");
    history.push("/Login");
    window.location.reload(false);
  };
  const [modalVisiblePassword, setModalVisiblePassword] = useState(false);
  const [passwordSet, setPasswordSet] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    error: "",
  });
  const passwordEmail = `${localStorage.getItem("patientuserEmail")}`;
  console.log('Email',passwordEmail)
  const [dashboardValues, setDashboardValues] = useState([]);
  const [data, setData] = useState("");
  const [image, setImage] = useState("");
  const [token, setToken] = useState(
    `${localStorage.getItem("dtt_PatientToken")}`
  );
  const patId = `${localStorage.getItem("dtt_PatientId")}`;

  const [CIDI, setCIDI] = useState(false);
  const [AUDIT, setAUDIT] = useState(false);
  const [CRAFFT, setCRAFFT] = useState(false);
  const [DAST, setDAST] = useState(false);
  const [MDQ, setMDQ] = useState(false);
  const [PTSD, setPTSD] = useState(false);
  const [YBCS, setYBCS] = useState(false);
///Change Password
const handleClosePassword = () => {
  setModalVisiblePassword(false);
  history.push("/Home");
};
const keyUpsetP = (e) => {
  if (e.key === "Enter") {
    onLoginPressedSet();
  }
};
const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};
const onLoginPressedSet = () => {
  const passwordError = passwordValidator(passwordSet.value);
  if (passwordError) {
    setPasswordSet({ ...passwordSet, error: passwordError });
    return;
  }
  if (passwordSet.value != confirmPassword.value) {
    setPasswordSet({ ...passwordSet, error: alert(`Password Donot Match`) });
    return;
  }
  const postData = {
    // id: parseInt(passwordId),

    emailAddress: passwordEmail,
    password: passwordSet.value,
    isActive: true,
    pin: "",
    isPINActive: true,
    practiceId: 0,
    createdDate: "",
    updatedDate: "",
    token: "",
  };

  console.log("PostData: ", postData.emailAddress);
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/PatientPortal/setPassword`,
      postData,
      axiosConfig
    )
    .then((response) => {
      console.warn(response);
      if (response.data) {
        history.push("/Login");
      } else {
        alert(`Opps!! Something went Wrong.... Try again`);
      }
    });
};
  // const ptodoId = `${localStorage.getItem("dtt_PatientId")}`;
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/PatientPortal/getPatientDetailsById?pateintid=${parseInt(patId)}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setDashboardValues(response.data);
        setImage(response.data.photoUrl);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientToDoList/getpatientToDoListById?patientid=${parseInt(patId)}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setData(response.data);
        response.data.forEach((element) => {
          console.log("ItemId.......................", element.toDoItemsId);
          if (element.toDoItemsId === 1) setCIDI(true);
          if (element.toDoItemsId == 2) setAUDIT(true);
          if (element.toDoItemsId === 3) setCRAFFT(true);
          if (element.toDoItemsId === 4) setDAST(true);
          if (element.toDoItemsId === 5) setMDQ(true);
          if (element.toDoItemsId === 6) setPTSD(true);
          if (element.toDoItemsId === 7) setYBCS(true);
        });
    
      })
      .catch((error) => {
        console.error("There was an error in patient todo", error);
      });
  }, []);

  return (
    <>
    <Modal show={modalVisiblePassword} onHide={handleClosePassword} style={{marginTop:'100px'}}>
        <Modal.Header closeButton>
          <Modal.Title>Please Set your Password.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ margin: "10px" }}>
            <Col>
              <label style={{ width: "40%", float: "center" }}>New Password:</label>
              <input
                style={{
                  backgroundColor: "#73C2E3",
                  border: "2px solid #fcbe13",
                  borderRadius: "10px",
                  height: "45px",
                  margin: "10px",
                  float: "center",
                }}
                returnKeyType="done"
                value={passwordSet.value}
                //  onKeyUp={keyUp}
                // value={email.value}
                onChange={(text) => {
                  // console.log("Test: ", text.target.value);
                  setPasswordSet({ value: text.target.value, error: "" });
                }}
                error={!!password.error}
                errorText={password.error}
                secureTextEntry
              />
            </Col>
          </Row>
          <Row style={{ margin: "10px" }}>
            <Col>
              <label style={{ width: "40%", float: "center" }}>
                Confirm Password:
              </label>

              <input
                style={{
                  backgroundColor: "#73C2E3",
                  border: "2px solid #fcbe13",
                  borderRadius: "10px",
                  height: "45px",
                  margin: "10px",
                  float: "center",
                }}
                returnKeyType="done"
                value={confirmPassword.value}
                onKeyUp={keyUpsetP}
                onChange={(text) =>
                  setConfirmPassword({ value: text.target.value, error: "" })
                }
                secureTextEntry
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button mode="contained" onClick={onLoginPressedSet}>
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    <nav className="navigation sticky-nav" bg="dark" variant="dark" style={{ zIndex: "1" }} >
      <img
        onClick={() => history.push("/Home")}
        src={logo}
        width="150px"
        height="65px"
        style={{ marginLeft: "2px" }}
      />
      <div>
        <button
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          {/* icon from Heroicons.com */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item"> */}
            <Link
              exact
              to="/Home"
              // class="nav-link active"
              // data-toggle="tab"
              // role="tab"
              // aria-controls="Home"
              // aria-selected="true"
            >
              Home
            </Link>
          </li>
          <li>
            {/* <Link activeClassName="active" to="/PHQ"> */}
            <Link
              exact
              to="/PHQ"
              // class="nav-link"
              // data-toggle="tab"
              // role="tab"
              // aria-controls="PHQ9"
              // aria-selected="false"
            >
              PHQ9
            </Link>
          </li>
          <li>
            <Link to="/GAD">GAD7</Link>
          </li>
          <li>
            <Link to="/Journal">Journal</Link>
          </li>
          <li>
            {/* <Link data-bs-toggle="tab" class="nav-link" to="/LearningDocuments">
              Learn
            </Link> */}
             <Link to="/LearningDocuments"> Learn</Link>
          </li>
          <li>
            {/* <Link data-bs-toggle="tab" class="nav-link" to="/Meditate">
              Meditate
            </Link> */}
             <Link to="/Meditate"> Meditate</Link>
          </li>
          <li>
            {/* <Link data-bs-toggle="tab" to="/CareTeam">
              Care Team
            </Link> */}
             <Link to="/CareTeam"> Care Team</Link>
          </li>
          <li>
          <Link to="/Videos">Videos</Link>
          </li>
          <li>
          <Link to="/Appointment">Appointment</Link>  
          </li>
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-basic"
              style={{
                marginTop: 13,
                backgroundColor: "#FCBE13",
                borderRadius: 80,
              }}
            >
              To Do
              <MDBBadge style={{ margin: 2 }} color="danger" notification pill>
                {data.length}
              </MDBBadge>
            </Dropdown.Toggle>

            {data.length > 0 ? (
              <Dropdown.Menu style={{ textAlign: "center" }}>
                {CIDI ? (
                  <>
                  <Dropdown.Item href="/CidiBased">
                    {/* <Link to="/CidiBased">CidiBased</Link> */}
                    CidiBased
                  </Dropdown.Item>
                <div class="dropdown-divider"></div>
                </>
                ) : null}

                {AUDIT ? (
                  <>
                  <Dropdown.Item href="/Audit">
                    {/* <Link to="/CidiBased">CidiBased</Link> */}
                    Audit-C
                  </Dropdown.Item>
                    <div class="dropdown-divider"></div>
                    </>
                ) : null}

                {CRAFFT ? (
                  <>
                  <Dropdown.Item href="/Crafft">Crafft</Dropdown.Item>
                <div class="dropdown-divider"></div></>
                ) : null}

                {DAST ? (
                  <>
                  <Dropdown.Item href="/Dast">
                    {/* <Link to="/CidiBased">CidiBased</Link> */}
                    Dast
                  </Dropdown.Item>
                  <div class="dropdown-divider"></div>
                  </>
                ) : null}

                {MDQ ? (<>
                  <Dropdown.Item href="/Mdq">
                    {/* <Link to="/CidiBased">CidiBased</Link> */}
                    MDQ
                  </Dropdown.Item>
                <div class="dropdown-divider"></div>
                </>
                ) : null}
                {PTSD ? (
                  <>
                  <Dropdown.Item href="/Ptsd">
                    {/* <Link to="/CidiBased">CidiBased</Link> */}
                    PTSD
                  </Dropdown.Item>
                <div class="dropdown-divider"></div>
                </>
                ) : null}
                {YBCS ? (
                  <Dropdown.Item href="/Ybocs">
                    {/* <Link to="/CidiBased">CidiBased</Link> */}
                    YBCS
                  </Dropdown.Item>
                ) : null}
                {/* </NavDropdown> */}
              </Dropdown.Menu>
            ) : (
              <Dropdown.Menu>
                <Dropdown.Item>
                  Questionnaire is not assigned yet!
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </ul>
      </div>
      <div className="navigation-pic">
  <NavDropdown
    title={
      <>
        {dashboardValues.lastName != null || dashboardValues.lastName != undefined ? (
          <label
            className="patientname"
            style={{
              color: "black",
              fontSize: 18,
              fontWeight: "bold",
              marginBottom: 5,
            }}
          >
            {`${dashboardValues.lastName}, ${dashboardValues.firstName}`}
          </label>
        ) : (
          // <label>Loading! Please Wait.</label>
          ''
        )}
        {image && image.length > 0 ? (
          <img
            className="imgPatient"
            style={{
              marginTop: "5px",
              width: "60px",
              height: "60px",
              margin: "5px",
              borderRadius: "100px",
              objectFit: "stretch",
            }}
            height="55 px"
            src={`${process.env.REACT_APP_Documents_URL}/${image}`}
          ></img>
        ) : (
          <img
            className="imgPatient"
            style={{
              marginTop: "5px",
              width: "60px",
              height: "60px",
              margin: "5px",
              borderRadius: "100px",
              objectFit: "stretch",
            }}
            height="55 px"
            src={pic}
          ></img>
        )}
      </>
    }
    id="basic-nav-dropdown"
  >
    <NavDropdown.Item onClick={() => {
      setModalVisiblePassword(true);
    }}>
      Change Password
    </NavDropdown.Item>
    <NavDropdown.Item onClick={logOutClickHandler}>
      Logout
    </NavDropdown.Item>
  </NavDropdown>
</div>

    </nav> 
    </>
  );
}
