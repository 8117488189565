import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Autosuggest from "react-autosuggest";
import zIndex from "@mui/material/styles/zIndex";
import Alert from "react-bootstrap/Alert";
// import 'react-autosuggest/dist/autosuggest.css';
import { createBrowserHistory } from "history";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { MdDelete } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
export default function Appointment() {
    const history = createBrowserHistory({ forceRefresh: true });
    const patId = `${localStorage.getItem("dtt_PatientId")}`;
    const provId = `${localStorage.getItem("dtt_providerId")}`;
    const [refresh, setRefresh] = useState(false)
    const locale = 'fr-CA';
    const [loading, setLoading] = useState(true);
    const [DisplayDate, setDisplayDate] = useState('');
    const today = new Date();
    const [rows, setRows] = useState([]);
    const [appdate, setAppDate] = useState(null);
    const [showSlotsLabel, setShowSlotsLabel] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const handleCloseAlert = () => {
        setOpenAlert(false);
    
      };
      const handleOpenAlert = () => {
        setOpenAlert(true);
    
      };
    const [token, setToken] = useState(
        `${localStorage.getItem("dtt_PatientToken")}`
    );

    const headers = {
        Authorization: `Bearer  ${localStorage.getItem("dtt_PatientToken")}`,
    };

    const [showModal, setShowModal] = useState(false);
    const [updateById, setupdateById] = useState("");
    console.log(updateById,'updateById')
    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        window.location.reload();
        setShowModal(false);
    };
    const [providerName, setProviderName] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [pname, setPname] = useState();
    const [dateslots, setDateslots] = useState([])
    const [selectedProvider, setSelectedProvider] = useState(null);
    console.log('selectedProvider',selectedProvider)
    const [showcalender, setshowcalender] = useState(false)
    const [Rowid, setRowid] = useState('')
        const [RowClicked, setRowidClick] = useState('')
    console.log(RowClicked,'RowClicked')
    console.log("hasssss", dateslots);



    async function Rowids() {

      


        if (!RowClicked) {
            console.error('Row ID is missing!');
            return;
          }
      

console.log(RowClicked,'id')

        const postData = {
          id:RowClicked,
        deleted:true
        };
        console.log("PostData: ", postData);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/PatientAppointment/AddPatientAppointment`,
            postData,
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            handleCloseAlert()
    setRefresh(!refresh)
          
          });
      }


 
    
    const [submitMessage, setSubmitMessage] = useState("");


    const inputProps = [
        'id',
        'providerName',

    ];
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/Providers/PatientPortlProvidersNames?patientId=${parseInt(patId)}`, {
                inputProps, headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${token}`,
                },
            },
            )
            .then((response) => {
                const newSuggestions = response.data.map((suggestion) => ({
                    value: suggestion.id,
                    label: suggestion.name,
                }));
                setSuggestions(newSuggestions);
                console.log('response.daytaa', response.name)
            })
            .catch((error) => {
                console.error("Error fetching provider name suggestions:", error);
            });
    }, []);
    const [selectedDates, setSelectedDates] = useState([]);
    useEffect(() => {
        // setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/PatientPortal/PatientAppointmentByPatientId?patientId=${parseInt(patId)}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                setLoading(false);
                setRows(response.data); // Assuming response.data is an array of objects
                console.log('responseee', response.data.appointmentDate);

            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }, [refresh]);

    useEffect(() => {
        if (selectedProvider && appdate) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/PatientPortal/AppointmentSlots?ProviderId=${selectedProvider.value}&Date=${appdate}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {

                    if (response.data.slots.length === 0) {
                        setDateslots([]);
                        setSubmitMessage("Sorry, Provider not available on selected Date. Please schedule for some other day.");
                    } else {
                        setDateslots(response.data.slots);
                        setSubmitMessage("");
                        setShowSlotsLabel(true);
                    }
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                    setDateslots([]);
                    setSubmitMessage("Sorry, Provider not available on selected Date. Please schedule for some other day.");
                });
        }
    }, [selectedProvider, appdate]);


    const handleProviderChange = (newValue) => {
        setSelectedProvider(newValue);
        setshowcalender(true)
    };
    const handleCalendarChange = (newDate) => {
        const year = newDate.getFullYear();
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
        const day = newDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}/${month}/${day}`;

        setAppDate(formattedDate);
        const displayDate = `${year}-${month}-${day}`;
        setDisplayDate(displayDate);
        setSubmitMessage("");
    };





    const handleSlotClick = (slot) => {
        const selectedTime = slot.trim();
        console.log("selected Time", selectedTime);
        // setLoading(true);
        if (dateslots.includes(selectedTime)) {
            if (window.confirm("Do you want to continue?")) {
                const postData = {
             
                    patientId: patId,
                    providerId: selectedProvider ? selectedProvider.value : "",
                    appointmentDate: DisplayDate,
                    startTime: selectedTime,

                };
                if (updateById !== "") {
                    postData.id = updateById;
                }
                axios
                    .post(
                        `${process.env.REACT_APP_API_URL}/PatientAppointment/AddPatientAppointment`, postData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then((response) => {
                        setLoading(false);
                        if (response.data) {
                            if (response.data === "Patient Appointment! Already Exist") {
                                // Handle the case where the appointment already exists
                                console.log("Submitting message", response.data.slots);
                                setSubmitMessage(response.data)
                            }

                            else {
                                setSubmitMessage("Appointment added successfully.");
                            }
                            // setTimeout(() => {
                            //     // Navigate to the appointment page after showing the message
                            //     history.push("/appointment");
                            // }, 2500);

                        }
                        else {
                            console.error("Error adding appointment");
                        }
                    })
                    .catch((error) => {
                        console.error("Error adding appointment:", error);
                        setLoading(false);
                    });
            } else {
                // User clicked "Cancel" in the confirmation dialog
                // Handle this case if needed
            }
        }
    };



    return (
        <>

            <div className="appointment" style={{ textAlign: 'right', margin: '1rem' }}>
            <p
                    style={{ backgroundColor: "",float:'left',fontWeight:'bold' }}
              
                >
                  Appointment
                </p>
                <Button
                    style={{ backgroundColor: "#FCBE13" }}
                    onClick={openModal}
                >
                    Add New Appointment
                </Button>
                {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={openModal}
                >
                    Add New Appointment
                </button> */}
            </div>

            {/* Modal container */}
            {showModal && (
                <div className="modal-container" >
                    <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {updateById?(                <h5 className="modal-title" id="exampleModalLongTitle">Update Appointment</h5>):
                                    (                                    <h5 className="modal-title" id="exampleModalLongTitle">Schedule Appointment</h5>
                                    )}
                
                                    <button type="button" className="close-button" onClick={closeModal}>
                                        <span className="close-icon" aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    
                                    <div className="formi" >
                                    <div className="row">
      <div className="col" style={{ width: '50%' }}>
        <form autoComplete="off" action="/">
          <div className="autocomplete">
            <label><b>Provider Name: </b></label>
            <Select
              value={selectedProvider}
              onChange={handleProviderChange}
              options={suggestions}
              isClearable
            />
          </div>

          {showcalender && (
            <div className="autocomplete1" style={{ marginTop: '0.5rem' }}>
              <label className="date-label"><b>Select Date:</b></label>
              <Calendar
                onChange={handleCalendarChange}
                value={appdate}
                minDate={today}
                dateFormat="mm/yy"
                className="calendar"
                style={{ width: '100%' }}
              />

              <div className="selected-dates">
                {selectedDates.map((date) => (
                  <span
                    key={date.toISOString()}
                    className="selected-date"
                  >
                    {date.toDateString()}
                  </span>
                ))}
                {submitMessage && (
                  <div
                    className={`submit-message ${submitMessage === "Appointment added successfully." ? "success" : "error"}`}
                  >
                    {submitMessage}
                  </div>
                )}
              </div>
            </div>
          )}
        </form>
      </div>

      {appdate && ( // conditionally render the second column only if a date is selected
        <div className="col" style={{ width: '50%' }}>
          <div className="autocomplete2" style={{ maxHeight: '450px', overflowY: 'auto' }}>
            {showSlotsLabel && dateslots.length > 0 ? (
              <div>
                {dateslots.map((slot, index) => (
                  <span
                    key={index}
                    style={{
                      display: 'inline-block',
                      margin: '0.25rem',
                      padding: '0.25rem 0.5rem',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      backgroundColor: '#f2f2f2',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSlotClick(slot)}
                  >
                    {slot}
                  </span>
                ))}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
    </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModal}>
                                        Close
                                    </button>
                                    {/* <button type="button" className="btn btn-primary">
                                        Save changes
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="formdata" style={{ width: '80vw', textAlign: 'center', border: '3px solid grey', margin: '7rem auto' }}>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Patient Name</th>
                                    <th scope="col">Provider Name</th>
                                    <th scope="col">Appointment Date</th>
                                    <th scope="col">Start Time</th>
                                    <th scope="col">End Time</th>
                                    <th scope="col">Durations</th>
                                    {/* <th scope="col">Delete</th> */}
                                    {/* Add more header columns */}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row) => (<>                                    <tr key={row.id}>
                                        <td scope="row">{row.id}</td>
                                        <td 
                                        // onClick={() => {openModal(row.id)
                                        // setupdateById(row.id) }} 
                                       scope="row">{row.patientName}</td>
                                        <td scope="row">{row.providerName}</td>
                                        <td scope="row">{row.appointmentDate}</td>
                                        <td scope="row">{row.startTime}</td>
                                        <td scope="row">{row.endTime}</td>
                                        <td scope="row">{row.duration}</td>
                                        </tr>
                                        {/* <td onClick={() =>{setOpenAlert(true)

setRowidClick(row.id)

                                        }     
                                        } scope="row"><MdDelete /></td>
                                      
                                  
                                    <Dialog
  open={openAlert}
  onClose={handleCloseAlert}
  aria-labelledby="responsive-dialog-title"
  PaperProps={{ elevation: 0 }}
  BackdropComponent={Backdrop}
  BackdropProps={{
    invisible: true,
  }}
>
  <DialogContent>
    <DialogContentText style={{ color: 'black' }}>
      Do you want to delete the record?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button autoFocus onClick={handleCloseAlert} style={{ color: 'white', backgroundColor: "#FCBE13" }}>
      No
    </Button>
    <Button onClick={() => { Rowids(row.id) }} style={{ color: 'white', backgroundColor: "#FCBE13" }} autoFocus>
      Yes
    </Button>
  </DialogActions>
</Dialog> */}

                                             </>

                                ))}
                       
                            </tbody>
                        </table>
                    </div>
                </div>

            )}
        </>
    )


}
