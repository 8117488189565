import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { createBrowserHistory } from "history";
// import Stepper from "react-stepper-horizontal";
import { BsFillReplyAllFill } from "react-icons/bs";
import Alert from "react-bootstrap/Alert";

// MUI Stepper imports
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Vertical Stepper code start
const steps = [
  {
    label: " AUDIT-C Questionnaire",
  },
];
// Vertical Stepper code end

export default function Audit() {
  // Vertical Stepper code start
  const [mainSteps, setVerticalSteps] = React.useState(0);
  const handleNext = () => {
    setVerticalSteps((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setVerticalSteps((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setVerticalSteps(0);
  };
  // Vertical Stepper code end

  const [date, setDate] = useState(new Date());
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [active, setActive] = useState(0);
  const [q1, setQ1] = useState(null);
  const [q2, setQ2] = useState(null);
  const [q3, setQ3] = useState(null);
  const [score, setScore] = useState(null);
  const [patientId, setpatientId] = useState(
    localStorage.getItem("dtt_PatientId")
  );
  const [modalVisible, setModalVisible] = useState(false);
  const handleClose = () => {
    setModalVisible(false);
    history.push("/Home");
  };
  const history = createBrowserHistory({ forceRefresh: true });
  const navigation = useNavigate();
  const token = `${localStorage.getItem("dtt_PatientToken")}`;
  function updateStep(step, newscore = 0) {
    onNextStep();
    console.log("step: ", step);
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) {
      setQ3(newscore);
    }
    setScore(score + newscore);
  }
  function previousStep(step, newscore = 0) {
    onPreviousStep();
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) {
      setQ3(newscore);
    }
    setScore(score + newscore);
  }
  useEffect(() => {
    if (q3 != null) saveGAD7Result();
  }, [q3]);
  async function saveGAD7Result() {
    const postData = {
      q1: q1,
      q2: q2,
      q3: q3,
      totalScore: score,
      source: "PatientPortalWeb",
      patientId: patientId,
      date:date
    };
    console.log("PostData: ", postData);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/addAuditc3`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(score);
        if (response.data.id > 0) {
          setModalVisible(!modalVisible);
          setTimeout(() => {
            history.push("/Home");
          }, 5000); // 5000 milliseconds = 5 seconds
        } else {
          // history.push("/Home");
        }
        
      });
  }
  const progressStepsStyle = {
    completedStepIconColor: "#686868",
    completedProgressBarColor: "#686868",
    completedCheckColor: "#4bb543",
  };
  const buttonTextStyle = {
    fontWeight: "bold",
  };
  function onNextStep() {
    setActive(active + 1);
    console.log("called next step:", active);
  }
  function onPreviousStep() {
    setActive(active - 1);
    console.log("called Previous step:", active);
  }
  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate !== null ? selectedDate : new Date();
  
    setDate(currentDate);
  };
  

  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode("date");
  };

  const showTimepicker = () => {
    showMode("time");
  };
  const initialStep = [
    {
      label:
        "1. Within the past year, how often did you have a drink of alcohol?",
      helpText: `a. Never<br/>
         b. Monthly (e.g. Special occasions/Rare)<br/>
         c. 2-4 times a month (e.g. 1x on weekend - “Fridays only” or “every other Thursday”)<br/>
         d. 2-3 times a week (e.g. weekends – Friday-Saturday or Saturday-Sunday)<br/>
         e. 4 or more times a week (e.g. daily or most days/week)`,
    },
    {
      label:
        "2. Within the past year, how many standard drinks containing alcohol did you have on a typical day?",
      helpText: `a. 1 or 2<br/>
         b. 3 or 4<br/>
         c. 5 or 6<br/>
         d. 7 to 9<br/>
         e. 10 or more`,
    },
    {
      label:
        "3. Within the past year, how often did you have six or more drinks on one occasion?",
      helpText: `a. Never<br/>
         b. Less than monthly<br/>
         c. Monthly<br/>
         d. Weekly<br/>
         e. Daily or almost daily
        `,
    },
  ];
  const styleConfig = { size: "50px" };
  const connectorStyleConfig = { stepSize: "10px" };
  const stepss = {
    // max-width: 90%;
    marginRight: "100px",
    marginLeft: "80px",
    padding: "100px",
  };

  const MyComponent = (props) => {
    console.log("Props:", props);
    return !props.title ? null : (
      <div>
        <Row>
          <label style={{ fontWeight: "bold" }}>{props.title.label}</label>
        </Row>
        <Stack spacing={2} direction="row" marginLeft={10} marginTop={3}>
          <Button
            variant="contained"
            size="lg"
            style={{ backgroundColor: "#FCBE13" }}
            onClick={() => {
              // if (active == 0) setVerticalSteps(0);
              updateStep(active + 1, 0);
            }}
          >
            a
          </Button>
          <Button
            size="lg"
            style={{ backgroundColor: "#FCBE13" }}
            variant="contained"
            onClick={() => {
              //   if (active == 0) setVerticalSteps(0);
              updateStep(active + 1, 1);
            }}
          >
            b
          </Button>
          <Button
            size="lg"
            style={{ backgroundColor: "#FCBE13" }}
            variant="contained"
            onClick={() => {
              //   if (active == 0) setVerticalSteps(0);
              updateStep(active + 1, 2);
            }}
          >
            c
          </Button>
          <Button
            size="lg"
            style={{ backgroundColor: "#FCBE13" }}
            variant="contained"
            onClick={() => {
              //   if (active == 0) setVerticalSteps(0);
              updateStep(active + 1, 3);
            }}
          >
            d
          </Button>
          <Button
            size="lg"
            style={{ backgroundColor: "#FCBE13" }}
            variant="contained"
            onClick={() => {
              //   if (active == 0) setVerticalSteps(0);
              updateStep(active + 1, 4);
            }}
          >
            e
          </Button>
        </Stack>
        <Row>
          {props.title.helpText ? (
            <div dangerouslySetInnerHTML={{ __html: props.title.helpText }} />
          ) : null}
        </Row>
      </div>
    );
  };
  return (
    // Vertical Stepper code start
    <Box>
      <Row>
        <h1 style={{ textAlign: "center", fontWeight: 600, marginTop: 20 }}>
          AUDIT-C Questionnaire
        </h1>
      </Row>
      <Row>
        <h3 style={{ textAlign: "center", fontWeight: 500, marginTop: 10 }}>
          Instructions: Please answer each question to the best of your ability
        </h3>
      </Row>
      <Box style={{ padding: 70, paddingTop: 10 }}>
        <Stepper activeStep={mainSteps} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption">Last step</Typography>
              //   ) : null
              // }
              >
                <h3>{step.label}</h3>
              </StepLabel>
              <StepContent>
                {/* <Typography>{step.description}</Typography> */}
                <Row xs={12} sm={12} md={12} lg={12}>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <MyComponent
                      title={active < 4 ? initialStep[active] : null}
                    />
                      <Modal
                    style={{ paddingTop: "75px", textAlign: "center" }}
                    show={modalVisible}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton style={{ padding: "5px" }}>
                      <Modal.Title
                        style={{ color: "#31A5D7", fontWeight: "bold" }}
                      >
                        Doing This Together
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ fontSize: "25px" }}>
                    Great job!<span style={{color:'yellow'}}> &#9733;</span> You're all done. Thanks for completing the questions!
                    </Modal.Body>
         
                  </Modal>
                    {/* <Modal
                      style={{ paddingTop: "75px", textAlign: "center" }}
                      show={modalVisible}
                      onHide={handleClose}
                    >
                      <Modal.Header closeButton style={{ padding: "5px" }}>
                        <Modal.Title
                          style={{ color: "#31A5D7", fontWeight: "bold" }}
                        >
                          Doing This Together
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={{ fontSize: "25px" }}>
                        I'm having thoughts of hurting myself!
                      </Modal.Body>
                      <Modal.Footer style={{ padding: "0px" }}>
                        <Container style={{ padding: "0px" }}>
                          <Row>
                            <Col xs={4} md={5} lg={5}></Col>
                            <Col
                              xs={2}
                              md={1}
                              lg={1}
                              style={{ margin: "3px", textAlign: "center" }}
                            >
                              <Button
                                style={{ backgroundColor: "#31A5D7" }}
                                onClick={handleClose}
                              >
                                No
                              </Button>{" "}
                            </Col>
                            <Col
                              xs={2}
                              md={1}
                              lg={1}
                              style={{ margin: "3px", textAlign: "center" }}
                            >
                              <Button
                                style={{ backgroundColor: "#31A5D7" }}
                                // variant="primary"
                                onClick={() => {
                                  setModalVisible(false);
                                  history.push("/Home");
                                  window.location.href =
                                    "https://suicidepreventionlifeline.org";
                                }}
                              >
                                Yes
                              </Button>{" "}
                            </Col>
                            <Col xs={4} md={5} lg={5}></Col>
                          </Row>
                        </Container>
                      </Modal.Footer>
                    </Modal> */}
                  </Col>
                </Row>
                {/* <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {/* {active === initialStep.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
          </Paper>
        )} */}
      </Box>
    </Box>

    // Vertical Stepper code end
  );
}
