import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// typescript style
import { useLocation, useNavigate } from "react-router-dom";

export default function HTMLViewer(props) {
  // console.log('PDFViewer: ', props)
  const [html, sethtml] = useState(""); // require(`../../src/${props.route.params.source}`)
  // const html = require(`${props.route.params.source}`)

  const location = useLocation();
  const oncall = "";
  const navigation = useNavigate();
  // const PolicyHTML = require(props.route.params.source)
  // return (<div dangerouslySetInnerHTML={{__html: props.route.params.source}} />)

  return (
     <Container>
         
           
        <div id="iframe_container" style={{position: 'fixed',top: '60px', bottom: '2px', left: '0px', right: '0px' }}>
<iframe name="ifr" scrolling="yes" style={{position: 'absolute', height:'100%', width:'100%'}} src={`${location.state.source}`} onload = "document.body.style.height = frames.ifr.document.body.offsetHeight + parseInt(document.getElementById('iframe_container').style.top) + parseInt(document.getElementById('iframe_container').style.bottom) + 'px'" >
</iframe>
</div>
</Container>
      
  );
}
