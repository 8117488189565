import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import logo from "../assets/logoMobilecopy.png";
import { Container, Row, Col } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

export default function MeditationDocsCard(props) {
  const list = props.list;
  const navigation = useNavigate();
  //
  return (
    <div>
      <Row xs={12} lg={12}>
        <Col xs={0} sm={0} md={0} lg={1}></Col>
        <Col xs={12} sm={12} md={12} lg={10}>
          {props.name ? (
            <>
              <Button
                variant="link"
                size="sm"
                style={{
                  color: "black",
                  height: "160px",
                  width: "80%",
                  fontSize: 38,
                  fontWeight: "bold",
                  fontFamily: "initial",
                  // margin: "5px",
                  padding: "0px",
                  backgroundColor: "#FEB313",
                  textAlign: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  props.file
                    ? navigation("../MainDocument", {
                      state: {
                        source: props.file,
                        callingFrom: "MeditationDocs",
                      },
                    })
                    : alert(
                      `Please Contact Administrator! Document Not Found. `
                    );
                }}
              >
                {props.name}
              </Button>
            </>
          ) : (
            ""
          )}
          {/* {props.mainImage ? (
            <img
              variant="link"
              onClick={() => {
                props.file ? (
                  navigation("../MainDocument", {
                    state: {
                      source: props.file,
                      callingFrom: "MeditationDocs",
                    },
                  })
                ) : (
                  <Alert variant="success">
                    Please Contact Administrator! Document Not Found.
                  </Alert>
                );
              }}
              src={props.mainImage}
              width="100%"
              height="250"
            />
          ) : null} */}
        </Col>
        <Col xs={0} sm={0} md={0} lg={1}></Col>
      </Row>
      <Row xs={12} sm={12} md={12} lg={12}>
        <Col xs={0} sm={0} md={0} lg={1}></Col>
        <Col xs={12} sm={12} md={12} lg={10}>
          <Button
            variant="link"
            size="sm"
            style={{
              color: "#32A6D6",
              fontSize: 20,
              fontWeight: "bold",
              width: "80%",
              // margin: "5px",
              padding: "0px",
              textAlign: "left",
              justifyContent: "left",
            }}
            onClick={() => {
              props.file
                ? navigation("../MainDocument", {
                  state: {
                    source: props.file,
                    callingFrom: "MeditationDocs",
                  },
                })
                : alert(`Please Contact Administrator! Document Not Found. `);
            }}
          >
            {props.name}
          </Button>
        </Col>
        <Col xs={0} sm={0} md={0} lg={1}></Col>
      </Row>
      <Row xs={12} sm={12} md={12} lg={12}>
        <Col xs={0} sm={0} md={0} lg={1}></Col>
        <Col xs={12} sm={12} md={12} lg={10}>
          <Button
            variant="outline"
            style={{
              marginBottom: 10,
              color: "black",
              marginLeft: "3px",
              padding: "0px",
              width: "80%",
              textAlign: "left",
              justifyContent: "left",
            }}
            onClick={() => {
              props.file
                ? navigation("../MainDocument", {
                  state: {
                    source: props.file,
                    callingFrom: "MeditationDocs",
                  },
                })
                : alert(`Please Contact Administrator! Document Not Found. `);
            }}
          >
            {props.description}
          </Button>
        </Col>
        <Col xs={0} sm={0} md={0} lg={1}></Col>
      </Row>
    </div>
  );
}
