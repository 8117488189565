import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { createBrowserHistory } from "history";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { MultilineInput } from "react-input-multiline";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
import { BiTrendingUp } from "react-icons/bi";

export default function JournalRegistration(props) {
  const location = useLocation();
  const history = createBrowserHistory({ forceRefresh: true });
  const [modalVisible, setModalVisible] = useState(false);

  const handleClose = () => {
    setModalVisible(false);
  };
  const navigation = useNavigate();
  //   const auth = `Bearer  ${AsyncStorage.getItem('dtt_token')}`
  const [name, setName] = useState({ value: "", error: "" });
  const [date, setDate] = useState({ value: "", error: "" });
  const [type, setType] = useState({ value: "", error: "" });
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [token, setToken] = useState(
    `${localStorage.getItem("dtt_PatientToken")}`
  );
  const [id, setId] = useState(location.state ? location.state.id : "");
  const [book, setBook] = useState(location.state ? location.state.list : "");
  const [index, setIndex] = useState(
    location.state ? location.state.index : ""
  );
  // console.log("index:", index);
  // console.log("id:", id);

  const [picture, setPicture] = useState("");
  /* if(id==0){
    setComment({value:''});
  } */
  const headers = {
    Authorization: `Bearer  ${localStorage.getItem("dtt_PatientToken")}`,
  };

  const patientId = `${localStorage.getItem("dtt_PatientId")}`;
  const dated = new Date().toLocaleString() + "";
  const [postData, setPostData] = useState({});
  const AddJournal = () => {
    setSubmitting(true);
    var pdata = postData;
    if (pdata == null) {
      pdata = {
        comment: comment,
        patientId: parseInt(patientId),
        id: id,
        name: "",
        date: "",
        type: "",
        notes: "",
        inactive: "",
        archive: "false",
        createdDate: "",
        updatedDate: "",
        createdBy: "",
        updatedBy: "",
        clientCreatedDate: "",
        clientUpdatedDate: "",
        pJournalAttachmentslist: [],
      };
    } else {
      pdata.comment = comment;
      pdata.patientId = parseInt(patientId);
      pdata.date = dated;
      // setPostData(pdata)
    }
    if (fileList.length > 0) {
      pdata.pJournalAttachmentslist = fileList;
    }
    console.log("pdata: ", pdata);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientJournal/AddPatientJournal`,
        pdata,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data) {
          history.push({
            pathname: `../Journal`,
          });
        } else {
          setSubmitting(false);
          alert(`Opps!! Something went Wrong.... Try again`);
        }
      });
  };
  const [singleFile, setSingleFile] = useState(true);

  var fileList = [];
  const [attachments, setattachments] = useState([]);
  const [dataReceived, setDataReceived] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  useEffect(() => {
    setDataReceived(false);

    if (id > 0) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/PatientJournal/PatientJournalById?Id=${parseInt(id)}`,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setPostData(response.data);
          setattachments(response.data.pJournalAttachmentslist);
          // console.warn("postData!", postData);
          setComment(response.data.comment);
          setDataReceived(true);
          // console.log("comment", response.data.comment);
        })
        .catch((error) => {
          console.warn("There was an error!", error);
        });
    } else setDataReceived(true);
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [id]);

  function Journal() {
    navigation.reset({
      index: 0,
      routes: [{ name: "Journal" }],
    });
  }
  const onFileChange = async (event) => {
    const file = event.target.files[0];
    var base64 = await convertBase64(file);
    base64 = base64.split("base64,")[1];
    // console.log("response : ", file);
    var fileObj = {
      base64: base64,
      base64Extantions: file.name,
    };
    var fList = fileList;
    fList.push(fileObj);
    fileList = fList;
    // setDataUpdated(true);
    // setSingleFile(!singleFile);
    console.log("singleFile : ", fileList);
    // console.log("File", base64);
    // console.log("File", event.target.files[0]);
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
  };
  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  return (
    <>
      {" "}
      {submitting ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            width: "50px",
            height: "50px",
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-25px",
            marginTop: "-25px",
            zIndex: 1000,
          }}
        />
      ) : null}
      <Container style={{ maxWidth: "100%" }}>
        {book.length > 0 ? (
          <Row style={{ padding: "10px" }}>
            <label style={{ fontWeight: "bold" }}>
              Date:{" "}
              <label style={{ fontWeight: "normal" }}>{book[index].date}</label>
            </label>
          </Row>
        ) : null}
        <Row style={{ padding: "10px" }}>
          {/* <BackButton goBack={Journal} /> */}
          {/* <input
        returnKeyType="next"
        placeholder="Name"
        value={name.value}
        onChangeText={(text) => setName({ value: text, error: '' })}
        error={!!name.error}
        errorText={name.error}
        autoCapitalize="none"
      /> */}

          {/* <input
        placeholder="Date"
        returnKeyType="next"
        value={date.value}
        onChangeText={(text) => setDate({ value: text, error: '' })}
        error={!!date.error}    
        errorText={date.error}
   
      {/* </InputGroup> */}
      {/* <Row>
        <Col className="text-left">
          <label
            style={{
              padding: "10px",
              fontWeight: "bold",
              fontSize: "30px",
              color: "#31A5D7",
            }}
          >
            Begin typing your journal entry here{" "}
          </label>
        </Col>
      </Row> */}
          {dataReceived ? (
            <MultilineInput
              value={dataReceived ? comment : ""}
              style={{fontSize:18,backgroundColor: "#31A5D7",padding: "20px",height:'450px'}} 
              placeholderTextColor="#000" 
               placeholder="Begin Typing Your Journal Entry Here"
              
              //   id="someId"
              onChange={(e) => setComment(e.target.value)}
              // style={{ padding: "20px", backgroundColor: "#31A5D7" }}
            />
          ) : null}
        </Row>
        <Row>
          {attachments && attachments.length > 0 ? (
            attachments.map(function (item, i) {
              // console.log("Attachments: ", `${process.env.REACT_APP_API_URL}`);
              return (
                <img
                  // style={styles.image}

                  style={{
                    width: "300px",
                    height: "150px",
                    margin: "5px",
                    objectFit: "stretch",
                  }}
                  src={`${process.env.REACT_APP_Documents_URL}/${encodeURI(
                    item.url
                  )}`}
                />
              );
            })
          ) : (
            <></>
          )}

          {/* {dataUpdated? (
          fileList.map(function (item, i) {
          console.log("file:", `${item.base64}`);
          return (
            <img
              style={{
                width: "300px",
                height: "150px",
                margin: "5px",
                objectFit: "stretch",
              }}
              src={`${item.base64}`}
            />
          );
        })):''} */}
        </Row>

        <Row>
          <Col className="text-center">
            <Button
              className="button"
              type="submit"
              style={{
                borderRadius: 5,
                margin: 5,
                color: "black",
                fontWeight: "bold",
                width: "205px",
                textAlign: "center",
                marginBottom: 10,
                backgroundColor: "#FCBE13",
              }}
              mode="contained"
              onClick={() => {
                document.getElementById("choosefile").click();
              }}
            >
              Add Picture/File
            </Button>
            {/* <input
               // as="input"
              type="file"
               style={{
                borderRadius: 5,
                margin: 5,
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 10,
                backgroundColor: "#FCBE13",
              }}
               onChange={onFileChange}
            /> */}

            <input
              id="choosefile"
              style={{ display: "none" }}
              type="file"
              onChange={onFileChange}
            />
            {/* <TouchableOpacity activeOpacity={0.5} style={{ justifyContent: 'center',padding:10,
    alignItems:'center',
    alignSelf:'center'}} > */}
            {/* <Button
            mode="contained"
            style={{ margin: "5px" }}
            //   onClick={openDocument}
          >
            Upload img
          </Button> */}
            {/* </TouchableOpacity> */}
            {/* <TouchableOpacity activeOpacity={0.5} onClick={uploadImage}>
        <Text>Upload File</Text>
      </TouchableOpacity> */}
            <Button
              className="button"
              type="submit"
              style={{
                borderRadius: 5,
                margin: 5,
                color: "black",
                width: "205px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 10,
                backgroundColor: "#FCBE13",
              }}
              mode="contained"
              disabled={submitting}
              onClick={AddJournal}
            >
              Save Journal Entry
            </Button>
            <Button
              className="button"
              type="submit"
              style={{
                borderRadius: 5,
                margin: 5,
                color: "black",
                width: "205px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 10,
                backgroundColor: "#FCBE13",
              }} // style={styles.button}
              mode="contained"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              Delete this Journal Entry
            </Button>
            <Modal
              style={{ paddingTop: "75px", textAlign: "center" }}
              show={modalVisible}
              onHide={handleClose}
            >
              <Modal.Header closeButton style={{ padding: "5px" }}>
                <Modal.Title style={{ color: "#31A5D7", fontWeight: "bold" }}>
                  Doing This Together
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: "25px" }}>
                Do you want to delete this journal entry?{" "}
              </Modal.Body>
              <Modal.Footer style={{ padding: "0px" }}>
                <Container style={{ padding: "0px" }}>
                  <Row>
                    <Col xs={4} md={5} lg={5}></Col>
                    <Col
                      xs={2}
                      md={1}
                      lg={1}
                      style={{ margin: "5px", textAlign: "center" }}
                    >
                      <Button
                        style={{ backgroundColor: "#31A5D7" }}
                        onClick={handleClose}
                      >
                        No
                      </Button>{" "}
                    </Col>
                    <Col
                      xs={2}
                      md={1}
                      lg={1}
                      style={{ margin: "5px", textAlign: "center" }}
                    >
                      <Button
                        style={{ backgroundColor: "#31A5D7" }}
                        // variant="primary"
                        onClick={() =>
                          axios
                            .post(
                              `${process.env.REACT_APP_API_URL}/PatientJournal/AddPatientJournal`,
                              {
                                id: postData.id,
                                inactive: true,
                                archive: postData.archive,
                                clientCreatedDate: postData.clientCreatedDate,
                                clientUpdatedDate: postData.clientUpdatedDate,
                                comment: postData.comment,
                                createdBy: postData.createdBy,
                                createdDate: postData.createdDate,
                                date: postData.date,
                                name: postData.name,
                                notes: postData.notes,
                                patientId: postData.patientId,
                                type: postData.type,
                                updatedBy: postData.updatedBy,
                                updatedDate: postData.updatedDate,
                                pJournalAttachmentslist:
                                  postData.pJournalAttachmentslist,
                              },
                              {
                                headers: {
                                  "Content-Type": "application/json",
                                  "Access-Control-Allow-Origin": "*",
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                            .then((response) => {
                              if (response.data) {
                                history.push({
                                  pathname: `../Journal`,
                                });
                              } else {
                                alert(
                                  `Opps!! Something went Wrong.... Try again`
                                );
                              }
                            })
                        }
                      >
                        Yes
                      </Button>{" "}
                    </Col>
                    <Col xs={4} md={5} lg={5}></Col>
                  </Row>
                </Container>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>

        <Row>
          <Col className="text-center">
            {index > 0 ? (
              <Button
                className="button"
                type="submit"
                style={{
                  borderRadius: 5,
                  width: "110px",
                  margin: 5,
                  color: "black",
                  fontWeight: "bold",
                  float: "left",
                  textAlign: "center",
                  marginBottom: 10,
                  backgroundColor: "#31A5D7",
                }}
                mode="contained"
                onClick={() => {
                  {
                    setId(book[index - 1].id);
                    setIndex(index - 1);
                  }
                }}
              >
                <GrPrevious style={{ marginBottom: "2px" }} />
                Previous
              </Button>
            ) : (
              ""
            )}
            {index + 1 < book.length ? (
              <Button
                className="button"
                type="submit"
                style={{
                  borderRadius: 5,
                  margin: 5,
                  width: "100px",
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  float: "right",
                  marginBottom: 10,
                  backgroundColor: "#31A5D7",
                }}
                mode="contained"
                onClick={() => {
                  {
                    setId(book[index + 1].id);
                    setIndex(index + 1);
                  }
                }}
              >
                Next <GrNext style={{ marginBottom: "2px" }} />
              </Button>
            ) : (
              ""
            )}
            {/* <Button
            style={{ margin: "5px",backgroundColor:'#FCBE13' }}
            // style={styles.button}
            mode="contained"
            onClick={() =>
              history.push({
                pathname: `/Journal`,
              })
            }
          >
            Go Back on Journal
          </Button> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}
