import Button from "react-bootstrap/Button";
import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

export default function LearningDocsCardTypes() {
  const location = useLocation();
  // console.log("cardTypes:", location);
  // console.log('list: ', props.route.params.list)
  const [data, setData] = useState(false);
  useEffect(() => {
    setData(true);
  }, [list]);
  var list = location.state.list;
  var name=location.state.name;
  const navigation = useNavigate();

  /* function LearningDocsCard() {
    navigation.reset({
      index: 0,
      routes: [{ name: "LearningDocsCardTypes", list: props.list }],
    });
  } */

  return (
    <Container>
        <Row style={{  position: 'sticky',backgroundColor:'#F0FFFF',
  top: '60px',
  zIndex: '99999'}}>
        <Col className="text-center">
          <label
            style={{
              padding: "10px",
              fontWeight: "bold",
              fontSize: "30px",
              color: "#31A5D7",
            }}
          >
          {name}
          </label>
        </Col>
      </Row>
      {data
        ? list.map(function (item, i) {
            return (
              <Row key={i}>
                <Col        
                //  style={{marginTop:'120px',alignContent:'center',alignItems:'center', alignSelf:'center'}}
 md={{ span: 4, offset: 4 }}>
                  {
                    item.image ? (
                      <img
                        variant="link"
                        onClick={() => {
                           item.file
                            ? navigation("../MainDocument", {
                                state: {
                                  source: item.file,
                                  callingFrom: "learningDocs",
                                },
                              })
                            : alert(
                                `Please Contact Administrator! Document Not Found. `
                              );
                        }}
                        src={item.image}
                        width="100%"
                        height="250"
                        style={{cursor: 'pointer'}}
                      />
                    ) : null
                    /*  <TouchableOpacity
                    onPress={() => {
                      item.file
                        ? navigation.navigate("PDFViewer", {
                            source: item.file,
                            callingFrom: "learningDocs",
                          })
                        : alert(
                            `Please Contact Administrator! Document Not Found. `
                          );
                    }}
                  >
                    {" "}
                    <img
                      source={{ uri: item.img }}
                      style={{ width: 330, height: 200 }}
                    />
                  </TouchableOpacity> */
                  }
                  <Button
                    variant="link"
                    size="sm"
                    style={{
                      color: "#32A6D6",
                      fontSize: 20,
                      marginBottom: "20px",
                      fontWeight: "bold",
                      margin: "5px",
                      textAlign: "left",
                      justifyContent: "left",
                    }}
                    onClick={() => {
                      item.file
                        ? navigation("../MainDocument", {
                            state: {
                              source: item.file,
                              callingFrom: "learningDocs",
                            },
                          })
                        : alert(
                            `Please Contact Administrator! Document Not Found. `
                          );
                    }}
                  >
                    {item.name}
                  </Button>
                </Col>
              </Row>
            );
          })
        : null}
      {/* <BackButton goBack={LearningDocsCard} /> */}
    </Container>
  );
}
// const styles = StyleSheet.create({
//   img: {

//     marginTop: 5,
//     // justifyContent: 'center',
//     // alignItems: 'center',
//     width: 400,
//     height: 250,
//     // marginLeft: '20px',
//   },

//   container: {
//     flex: 1,
//     flexDirection:'row',
//     padding: 20,
//     width: 100,
//     maxWidth: 340,
//     // alignSelf: 'left',
//     // alignItems: 'center',
//     // justifyContent: 'center',
//   },
//   container1: {
//     flex: 1,
//     // justifyContent: 'center',
//   },
//   buttonContainer: {
//     margin: 20,
//   },
//   item: {
//     width: 50, // is 50% of container width
//   },
//   column1: {
//     // border: '2px solid Black',
//     // alignSelf: 'left',
//     // alignItems: 'left',
//     // justifyContent: 'left',
//   },
//   Background: {
//     // border: '2px solid Black',
//     // alignSelf: 'left',
//     // alignItems: 'left',
//     // justifyContent: 'left',
//   },
//   // column2: {
//   //   width: '40%',
//   //   marginRight: '10%',
//   //   marginBottom: '20px',
//   //   border: '2px solid Black',
//   // },
// })
