import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import logo from "../assets/logoMobilecopy.png";
import { Container, Row, Col } from "react-bootstrap";

export default function LearningDocsCard(props) {
  const list = props.list;
  const navigation = useNavigate();
  // function avatarimg (img) {
  //   switch (img) {
  //     case 'New.png':
  //       return require('../LearningDocs/img s/New.png')
  //       case 'New_a1.png':
  //         return require('../LearningDocs/img s/New_a1.png')
  //   }
  // }
  // const logo = require("../assets/logoMobilecopy.png");
  return (
    <div>
      <Row xs={12}>
        <Col xs={1} sm={2} md={2} lg={2}></Col>
        <Col xs={10} sm={8} md={8} lg={8}>
          <img
            onClick={() =>
              navigation("../LearningDocumentTypes", {
                state: { list: props.list, name:props.name },
              })
            }
            src={props.mainImage}
            width="100%"
            height="250"
            style={{cursor: 'pointer'}}
          />
        </Col>
        <Col xs={1} sm={2} md={2} lg={2}></Col>
      </Row>
      <Row xs={12} sm={12} md={12} lg={12}>
        <Col xs={1} sm={2} md={2} lg={2}></Col>
        <Col xs={10} sm={8} md={8} lg={8}>
          <Button
            variant="link"
            size="sm"
            style={{
              color: "#32A6D6",
              fontSize: 20,
              marginBottom: "10px",
              fontWeight: "bold",
              // margin: "3px",
              // padding: "0px",
              textAlign: "left",
              justifyContent: "left",
            }}
            onClick={() =>
              navigation("../LearningDocumentTypes", {
                state: { list: props.list, name:props.name },
              })
            }
          >
            {props.name}
          </Button>
        </Col>
        <Col xs={1} sm={2} md={2} lg={2}></Col>
      </Row>
      {/* <Row xs={12} sm={12} md={12} lg={12}>
        <Col xs={1} sm={2} md={2} lg={2}></Col>
        <Col xs={10} sm={8} md={8} lg={8}>
          <Button
            variant="outline"
            style={{
              marginBottom: 10,
              color: "black",
              marginLeft: "3px",
              padding: "0px",
              textAlign: "left",
              justifyContent: "left",
            }}
            onClick={() =>
              navigation("../LearningDocumentTypes", {
                state: { list: props.list },
              })
            }
          >
            {props.description}
          </Button>
        </Col>
        <Col xs={1} sm={2} md={2} lg={2}></Col>
      </Row> */}
    </div>
  );
}
