import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Navbar from "./components/Navbar";
import "./styles.css";
import Home from "./screens/Home";
import GAD from "./screens/GAD";
import PHQ from "./screens/PHQ";
import Journal from "./screens/Journal";
import LearningDoc from "./screens/LearningDoc";
import Meditate from "./screens/Meditate";
import CareTeam from "./screens/CareTeam";
import Videos from "./screens/Videos";
import LearningDocsCardTypes from "./components/LearningDocsCardTypes";
import HTMLViewer from "./components/HTMLViewer";
import JournalRegistration from "./screens/JournalRegistration";
import CidiBased from "./screens/CidiBased";
import Audit from "./screens/Audit";
import Crafft from "./screens/Crafft";
import Dast from "./screens/Dast";
import Mdq from "./screens/Mdq";
import Ptsd from "./screens/Ptsd";
import Ybocs from "./screens/Ybocs";
import Appointment from "./screens/Appointment";
import Login from "./screens/Login";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const isNotLoggedIn =
    localStorage.getItem("dtt_PatientToken") == null ||
    localStorage.getItem("dtt_PatientToken").length === 0;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userNameFromParams = params.get("UserName");
    const passwordFromParams = params.get("Password");
    const redirect = params.get("redirect");

    if (userNameFromParams && passwordFromParams) {
      localStorage.setItem("UserName", userNameFromParams);
      localStorage.setItem("Password", passwordFromParams);
      localStorage.setItem("redirect", redirect);
      navigate("/login");
    }
  }, [location, navigate]);

  useEffect(() => {
    const redirect = localStorage.getItem("redirect");

    if (redirect === "phq9") {
      navigate("/PHQ");
      localStorage.removeItem("redirect");
    }
  }, [navigate]);

  return (
    <div className="App">
      {isNotLoggedIn ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Navigate to="/Home" />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/GAD" element={<GAD />} />
            <Route path="/PHQ" element={<PHQ />} />
            <Route path="/Journal" element={<Journal />} />
            <Route
              path="/JournalRegistration"
              element={<JournalRegistration />}
            />
            <Route path="/LearningDocuments" element={<LearningDoc />} />
            <Route path="/Meditate" element={<Meditate />} />
            <Route path="/CareTeam" element={<CareTeam />} />
            <Route path="/Videos" element={<Videos />} />
            <Route path="/Appointment" element={<Appointment />} />
            <Route path="/CidiBased" element={<CidiBased />} />
            <Route path="/Audit" element={<Audit />} />
            <Route path="/Crafft" element={<Crafft />} />
            <Route path="/Dast" element={<Dast />} />
            <Route path="/Mdq" element={<Mdq />} />
            <Route path="/Ptsd" element={<Ptsd />} />
            <Route path="/Ybocs" element={<Ybocs />} />
            <Route path="/MainDocument" element={<HTMLViewer />} />
            <Route
              path="/LearningDocumentTypes"
              element={<LearningDocsCardTypes />}
            />
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
