import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { createBrowserHistory } from "history";
// import Stepper from "react-stepper-horizontal";
import { BsFillReplyAllFill } from "react-icons/bs";
// import { createTheme } from "@mui/material";
// import { ThemeProvider } from "@material-ui/styles";
// import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import Alert from "react-bootstrap/Alert";

// MUI Stepper imports
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Vertical Stepper code start
const steps = [
  {
    label: " AGGRESSIVE OBSESSIONS",
  },
  {
    label: " CONTAMINATION OBSESSIONS",
  },
  {
    label: " SEXUAL OBSESSIONS",
  },
  {
    label: " MOARDING/SAVING OBSESSIONS",
  },
  {
    label: " RELIGIOUS OBSESSIONS",
  },
  {
    label: " OBSESSION WITH NEED FOR SYMMETRY OR EXACTNESS",
  },
  {
    label: " MISCELLANEOUS OBSESSIONS",
  },
  {
    label: " SOMATIC OBSESSIONS",
  },
  {
    label: " CLEANING/WASHING COMPULSIONS",
  },
  {
    label: " CHECKING COMPULSIONS",
  },
  {
    label: " REPEATING RITUALS",
  },
  {
    label: " HOARDING/COLLECTING COMPULSIONS",
  },
  {
    label: " MISCELLANEOUS COMPULSIONS     ",
  },
  {
    label: "1. TIME OCCUPED BY OBSESSIVE THOUGHTS",
  },
  {
    label: "I b. OBSESSION-FREE INTERVAL (not included in total score) ",
  },
  {
    label: "2. INTERFERENCE DUE TO OBSESSIVE THOUGHTS",
  },
  {
    label: "3. DISTRESS ASSOCIATED W1TH OBSESSIVE THOUGHTS",
  },
  {
    label: "4. RESISTANCE AGAINST OBSESSIONS     ",
  },
  {
    label: "5. DEGREE OP CONTROL OVER OBSESSIVE THOUGHTS     ",
  },
  {
    label: "6. TIME SPENT PERFORM~G COMPULSIVE BEHAVIORS     ",
  },
  {
    label: "6b. COMPULSION-FREE INTERVAL(not included in total score)     ",
  },
  {
    label: "7 INTERFERIINCE DUE TO COMPULSIVE BEHAVIQRS     ",
  },
  {
    label: "8. DISTRESS ASSOCIATED WITH COMPULSIVE BEHAVIOR    ",
  },
  {
    label: "9. RESISTANCE AGAINST COMPULSIONS     ",
  },
  {
    label: "10. DEGREE OF CONTROL OVER COMULSIVE BEHAVIOR    ",
  },
  {
    label: "11. INSIGHT INTO OBSESSIONS AND COMPULSIONS     ",
  },
  {
    label: "12. AVOIDANCE    ",
  },
  {
    label: "13. DEGEE OF INDECISIVENESS     ",
  },
  {
    label: "14. OVERVALUED SENSE OF RESPONSIBILY    ",
  },
  {
    label: "15. PERVASIVE SLOWNESS/ DISTURBANCE OF INERTIA    ",
  },
  {
    label: "16. PATHOLOGICAL DOUBTING     ",
  },
  {
    label: "17. GLOBAL SEVERITY:     ",
  },
  {
    label: "18. GLOBAL IMPROVEMENT:    ",
  },
  {
    label: "19. RELIABILITY:     ",
  },
];
// Vertical Stepper code end

export default function Ybocs() {
  const theme = createTheme({ stepper: { fontSize: 50 } });

  // Vertical Stepper code start
  const [mainSteps, setVerticalSteps] = React.useState(0);
  const handleNext = () => {
    setVerticalSteps((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setVerticalSteps((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setVerticalSteps(0);
  };
  // Vertical Stepper code end

  const [date, setDate] = useState(new Date());

  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
  const [active, setActive] = useState(0);
  const [q1, setQ1] = useState(0);

  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);
  const [q5, setQ5] = useState(0);
  const [q6, setQ6] = useState(0);
  const [q7, setQ7] = useState(0);
  const [q8, setQ8] = useState(0);
  const [q9, setQ9] = useState(0);
  const [q10, setQ10] = useState(0);
  const [q11, setQ11] = useState(0);
  const [q12, setQ12] = useState(0);
  const [q13, setQ13] = useState(0);
  const [q14, setQ14] = useState(0);
  const [q15, setQ15] = useState(0);
  const [q16, setQ16] = useState(0);
  const [q17, setQ17] = useState(0);
  const [q18, setQ18] = useState(0);
  const [q19, setQ19] = useState(0);
  const [q20, setQ20] = useState(0);
  const [q21, setQ21] = useState(0);
  const [q22, setQ22] = useState(0);
  const [q23, setQ23] = useState(0);
  const [q24, setQ24] = useState(0);
  const [q25, setQ25] = useState(0);
  const [q26, setQ26] = useState(0);
  const [q27, setQ27] = useState(0);
  const [q28, setQ28] = useState(0);
  const [q29, setQ29] = useState(0);
  const [q30, setQ30] = useState(0);
  const [q31, setQ31] = useState(0);
  const [q32, setQ32] = useState(0);
  const [q33, setQ33] = useState(0);
  const [q34, setQ34] = useState(0);
  const [q35, setQ35] = useState(0);
  const [q36, setQ36] = useState(0);
  const [q37, setQ37] = useState(0);
  const [q38, setQ38] = useState(0);
  const [q39, setQ39] = useState(0);
  const [q40, setQ40] = useState(0);
  const [q41, setQ41] = useState(0);
  const [q42, setQ42] = useState(0);
  const [q43, setQ43] = useState(0);
  const [q44, setQ44] = useState(0);
  const [q45, setQ45] = useState(0);
  const [q46, setQ46] = useState(0);
  const [q47, setQ47] = useState(0);
  const [q48, setQ48] = useState(0);
  const [q49, setQ49] = useState(0);
  const [q50, setQ50] = useState(0);
  const [q51, setQ51] = useState(0);
  const [q52, setQ52] = useState(0);
  const [q53, setQ53] = useState(0);
  const [q54, setQ54] = useState(0);
  const [q55, setQ55] = useState(0);
  const [q56, setQ56] = useState(0);
  const [q57, setQ57] = useState(0);
  const [q58, setQ58] = useState(0);
  const [q59, setQ59] = useState(0);
  const [q60, setQ60] = useState(0);
  const [q61, setQ61] = useState(0);
  const [q62, setQ62] = useState(0);
  const [q63, setQ63] = useState(0);
  const [q64, setQ64] = useState(0);
  const [q65, setQ65] = useState(0);
  const [q66, setQ66] = useState(0);
  const [q67, setQ67] = useState(0);
  const [q68, setQ68] = useState(0);
  const [q69, setQ69] = useState(0);
  const [q70, setQ70] = useState(0);
  const [q71, setQ71] = useState(0);
  const [q72, setQ72] = useState(0);
  const [q73, setQ73] = useState(0);
  const [q74, setQ74] = useState(0);
  const [q75, setQ75] = useState(0);
  const [q76, setQ76] = useState(0);
  const [q77, setQ77] = useState(0);
  const [q78, setQ78] = useState(0);
  const [q79, setQ79] = useState(0);
  const [q80, setQ80] = useState(0);
  const [q81, setQ81] = useState(0);
  const [q82, setQ82] = useState(0);
  const [q83, setQ83] = useState(0);
  const [q84, setQ84] = useState(0);
  const [q85, setQ85] = useState(0);
  const [q86, setQ86] = useState(0);
  const [q87, setQ87] = useState(0);
  const [q88, setQ88] = useState(0);
  const [q89, setQ89] = useState(0);
  const [q90, setQ90] = useState(0);
  console.log(q90, 'q90,,')


  const [score, setScore] = useState(0);
  const [patientId, setpatientId] = useState(
    localStorage.getItem("dtt_PatientId")
  );
  const [modalVisible, setModalVisible] = useState(false);
  const handleClose = () => {
    setModalVisible(false);
    // history.push("/Home");
  };
  const history = createBrowserHistory({ forceRefresh: true });
  const navigation = useNavigate();
  const token = `${localStorage.getItem("dtt_PatientToken")}`;
  function updateStep(step, newscore = 0) {
    onNextStep();
    console.log("step: ", step);
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) setQ7(newscore);
    if (step === 8) setQ8(newscore);
    if (step === 9) setQ9(newscore);
    if (step === 10) setQ10(newscore);
    if (step === 11) setQ11(newscore);
    if (step === 12) setQ12(newscore);
    if (step === 13) setQ13(newscore);
    if (step === 14) setQ14(newscore);
    if (step === 15) setQ15(newscore);
    if (step === 16) setQ16(newscore);
    if (step === 17) setQ17(newscore);
    if (step === 18) setQ18(newscore);
    if (step === 19) setQ19(newscore);
    if (step === 20) setQ20(newscore);
    if (step === 21) setQ21(newscore);
    if (step === 22) setQ22(newscore);
    if (step === 23) setQ23(newscore);
    if (step === 24) setQ24(newscore);
    if (step === 25) setQ25(newscore);
    if (step === 26) setQ26(newscore);
    if (step === 27) setQ27(newscore);
    if (step === 28) setQ28(newscore);
    if (step === 29) setQ29(newscore);
    if (step === 30) setQ30(newscore);
    if (step === 31) setQ31(newscore);
    if (step === 32) setQ32(newscore);
    if (step === 33) setQ33(newscore);
    if (step === 34) setQ34(newscore);
    if (step === 35) setQ35(newscore);
    if (step === 36) setQ36(newscore);
    if (step === 37) setQ37(newscore);
    if (step === 38) setQ38(newscore);
    if (step === 39) setQ39(newscore);
    if (step === 40) setQ40(newscore);
    if (step === 41) setQ41(newscore);
    if (step === 42) setQ42(newscore);
    if (step === 43) setQ43(newscore);
    if (step === 44) setQ44(newscore);
    if (step === 45) setQ45(newscore);
    if (step === 46) setQ46(newscore);
    if (step === 47) setQ47(newscore);
    if (step === 48) setQ48(newscore);
    if (step === 49) setQ49(newscore);
    if (step === 50) setQ50(newscore);
    if (step === 51) setQ51(newscore);
    if (step === 52) setQ52(newscore);
    if (step === 53) setQ53(newscore);
    if (step === 54) setQ54(newscore);
    if (step === 55) setQ55(newscore);
    if (step === 56) setQ56(newscore);
    if (step === 57) setQ57(newscore);
    if (step === 58) setQ58(newscore);
    if (step === 59) setQ59(newscore);
    if (step === 60) setQ60(newscore);
    if (step === 61) setQ61(newscore);
    if (step === 62) setQ62(newscore);
    if (step === 63) setQ63(newscore);
    if (step === 64) setQ64(newscore);
    if (step === 65) setQ65(newscore);
    if (step === 66) setQ66(newscore);
    if (step === 67) setQ67(newscore);
    if (step === 68) setQ68(newscore);
    if (step === 69) setQ69(newscore);
    if (step === 70) setQ70(newscore);
    if (step === 71) setQ71(newscore);
    if (step === 72) setQ72(newscore);
    if (step === 73) setQ73(newscore);
    if (step === 74) setQ74(newscore);
    if (step === 75) setQ75(newscore);
    if (step === 76) setQ76(newscore);
    if (step === 77) setQ77(newscore);
    if (step === 78) setQ78(newscore);
    if (step === 79) setQ79(newscore);
    if (step === 80) setQ80(newscore);
    if (step === 81) setQ81(newscore);
    if (step === 82) setQ82(newscore);
    if (step === 83) setQ83(newscore);
    if (step === 84) setQ84(newscore);
    if (step === 85) setQ85(newscore);
    if (step === 86) setQ86(newscore);
    if (step === 87) setQ87(newscore);
    if (step === 88) setQ88(newscore);
    if (step === 89) setQ89(newscore);
    if (step === 90) {
      setQ90(newscore);
      saveGAD7Result();
      setModalVisible(!modalVisible);
      setTimeout(() => {
        history.push("/Home");
      }, 5000); 

    }
    // if (step === 90) {
    //   setQ90(newscore);
    //   saveGAD7Result() 
    //   window.alert('akjdtjdh')
    // }

    setScore(score + newscore);
  }
  //   function previousStep(step, newscore = 0) {
  //     onPreviousStep();
  //     if (step === 1) setQ1(newscore);
  //     if (step === 2) setQ2(newscore);
  //     if (step === 3) setQ3(newscore);
  //     if (step === 4) setQ4(newscore);
  //     if (step === 5) setQ5(newscore);
  //     if (step === 6) setQ6(newscore);
  //     if (step === 7) setQ7(newscore);
  //     if (step === 8) setQ8(newscore);
  //     if (step === 9) setQ9(newscore);
  //     if (step === 10) setQ10(newscore);
  //     if (step === 11) setQ11(newscore);
  //     if (step === 12) setQ12(newscore);
  //     if (step === 13) setQ13(newscore);
  //     if (step === 14) setQ14(newscore);
  //     if (step === 15) setQ15(newscore);
  //     if (step === 16) setQ16(newscore);
  //     if (step === 17) setQ17(newscore);
  //     if (step === 18) setQ18(newscore);
  //     if (step === 19) setQ19(newscore);
  //     if (step === 20) setQ20(newscore);
  //     if (step === 21) setQ21(newscore);
  //     if (step === 22) setQ22(newscore);
  //     if (step === 23) setQ23(newscore);
  //     if (step === 24) setQ24(newscore);
  //     if (step === 25) setQ25(newscore);
  //     if (step === 26) setQ26(newscore);
  //     if (step === 27) setQ27(newscore);
  //     if (step === 28) setQ28(newscore);
  //     if (step === 29) setQ29(newscore);
  //     if (step === 30) setQ30(newscore);
  //     if (step === 31) setQ31(newscore);
  //     if (step === 32) setQ32(newscore);
  //     if (step === 33) setQ33(newscore);
  //     if (step === 34) setQ34(newscore);
  //     if (step === 35) setQ35(newscore);
  //     if (step === 36) setQ36(newscore);
  //     if (step === 37) setQ37(newscore);
  //     if (step === 38) setQ38(newscore);
  //     if (step === 39) setQ39(newscore);
  //     if (step === 40) setQ40(newscore);
  //     if (step === 41) setQ41(newscore);
  //     if (step === 42) setQ42(newscore);
  //     if (step === 43) setQ43(newscore);
  //     if (step === 44) setQ44(newscore);
  //     if (step === 45) setQ45(newscore);
  //     if (step === 46) setQ46(newscore);
  //     if (step === 47) setQ47(newscore);
  //     if (step === 48) setQ48(newscore);
  //     if (step === 49) setQ49(newscore);
  //     if (step === 50) setQ50(newscore);
  //     if (step === 51) setQ51(newscore);
  //     if (step === 52) setQ52(newscore);
  //     if (step === 53) setQ53(newscore);
  //     if (step === 54) setQ54(newscore);
  //     if (step === 55) setQ55(newscore);
  //     if (step === 56) setQ56(newscore);
  //     if (step === 57) setQ57(newscore);
  //     if (step === 58) setQ58(newscore);
  //     if (step === 59) setQ59(newscore);
  //     if (step === 60) setQ60(newscore);
  //     if (step === 61) setQ61(newscore);
  //     if (step === 62) setQ62(newscore);
  //     if (step === 63) setQ63(newscore);
  //     if (step === 64) setQ64(newscore);
  //     if (step === 65) setQ65(newscore);
  //     if (step === 66) setQ66(newscore);
  //     if (step === 67) setQ67(newscore);
  //     if (step === 68) setQ68(newscore);
  //     if (step === 69) {
  //       setQ69(newscore);
  //     }
  //     setScore(score + newscore);
  //   }

  async function saveGAD7Result() {
    const postData = {
      q1: q1,
      q2: q2,
      q3: q3,
      q4: q4,
      q5: q5,
      q6: q6,
      q7: q7,
      q8: q8,
      q9: q9,
      q10: q10,
      q11: q11,
      q12: q12,
      q13: q13,
      q14: q14,
      q15: q15,
      q16: q16,
      q17: q17,
      q18: q18,
      q19: q19,
      q20: q20,
      q21: q21,
      q22: q22,
      q23: q23,
      q24: q24,
      q25: q25,
      q26: q26,
      q27: q27,
      q28: q28,
      q29: q29,
      q30: q30,
      q31: q31,
      q32: q32,
      q33: q33,
      q34: q34,
      q35: q35,
      q36: q36,
      q37: q37,
      q38: q38,
      q39: q39,
      q40: q40,
      q41: q41,
      q42: q42,
      q43: q43,
      q44: q44,
      q45: q45,
      q46: q46,
      q47: q47,
      q48: q48,
      q49: q49,
      q50: q50,
      q51: q51,
      q52: q52,
      q53: q53,
      q54: q54,
      q55: q55,
      q56: q56,
      q57: q57,
      q58: q58,
      q59: q59,
      q60: q60,
      q61: q61,
      q62: q62,
      q63: q63,
      q64: q64,
      q65: q65,
      q66: q66,
      q67: q67,
      q68: q68,
      q69: q69,
      q70: q70,
      q71: q71,
      q71: q71,
      q71: q71,
      q71: q71,
      q72: q72,
      q73: q73,
      q74: q74,
      q75: q75,
      q76: q76,
      q77: q77,
      q78: q78,
      q79: q79,
      q80: q80,
      q81: q81,
      q82: q82,
      q83: q83,
      q84: q84,
      q85: q85,
      q86: q86,
      q87: q87,
      q88: q88,
      q89: q89,
      q90: q90,
      totalScore: score,
      source: "PatientPortalWeb",
      patientId: patientId,
      date:date
    };

    console.log("PostData: ", postData);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/addYbocs90`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response:", response.data);

        if (response.data.id > 0) {
          setModalVisible(!modalVisible);
          setTimeout(() => {
            history.push("/Home");
          }, 5000); // 5000 milliseconds = 5 seconds
        } else {
          // history.push("/Home");
        }
    } catch (error) {
      console.error("Error:", error);
      // Handle error as needed
    }
  }

  //   const progressStepsStyle = {
  //     completedStepIconColor: "#686868",
  //     completedProgressBarColor: "#686868",
  //     completedCheckColor: "#4bb543",
  //   };
  //   const buttonTextStyle = {
  //     fontWeight: "bold",
  //   };
  function onNextStep() {
    setActive(active + 1);
    console.log("called next step:", active);
  }
  //   function onPreviousStep() {
  //     setActive(active - 1);
  //     console.log("called Previous step:", active);
  //   }
  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || new Date(); // Use current date if selectedDate is null
  
    setDate(currentDate);
  };
  

  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

    const showDatepicker = () => {
      showMode("date");
    };

    const showTimepicker = () => {
      showMode("time");
    };
  const initialStep = [
    {
      label: `1.Fear might harm self`,
    },
    {
      label: "2. Fear might harm others  ",
    },
    {
      label: "3. Violent or horrific images   ",
    },
    {
      label: "4. Fear of blurting out obscenities or insults    ",
    },
    {
      label: "5. Fear of doing something else embarrassing *    ",
    },
    {
      label: "6. Fear will act on unwanted impulses (e.g. to stab friend)  ",
    },
    {
      label: "7. Fear will steal things  ",
    },
    {
      label:
        "8. Fear will harm others because not careful enough (e.g. hit/run MVA)  ",
    },
    {
      label:
        "9. Fear will be responsible for something else terrible happening (e.g. fire  burglary) ",
    },
    {
      label: "10. Others",
    },
    {
      label:
        "11. Concerns or disgust with bodily waste or secretions (e.g. urine, feces, saliva) ",
    },
    {
      label: "12. Concern with dirt or germs",
    },
    {
      label:
        "13. Excessive concern with environmental contarninants (c.g. asbestos, radiation, toxic waste)",
    },
    {
      label:
        "14. Excessive concern with household items (e.g. cleansers, solvents, )  ",
    },
    {
      label: "15. Excessive concern with animals (e.g. insects)   ",
    },
    {
      label: "16. Bothered by sticky substances or residues ",
    },
    {
      label: "17. Concerned will get ill because of contaminant  ",
    },
    {
      label:
        "18. Concerned will get others ill by spreading contaminant (Aggressive)  ",
    },
    {
      label:
        "19. No concern with consequences of contarnination other than how it might  feel  ",
    },
    {
      label: "20. Other",
    },
    {
      label:
        "21. Forbidden or perverse sexual thoughts, images, or impulses   ",
    },
    {
      label: "22. Content involves children or incest ",
    },
    {
      label: "23. Content involves homosexuality *    ",
    },
    {
      label: "24. Sexual behavior toward others (Aggressive)*       ",
    },
    {
      label: "25. Other",
    },
    {
      label:
        "26. [distinguish from hobbies and concern with objects of monetary or sentimental value]",
    },
    {
      label: "27. (Scrupulosity) Concerned with sacrilege and blasphemy   ",
    },
    {
      label: "28. Excess concern with right/wrong, morality      ",
    },
    {
      label: "29. Other",
    },
    {
      label:
        "30. (Accompanied by magical thinking (c.x., concerned the mother will have accident unless things and in the right place) ",
    },
    {
      label: "31. Not accompanied by magical thinking  ",
    },
    {
      label: "32. Need to know or remember  ",
    },
    {
      label: "33. Fear of saying certain things  ",
    },
    {
      label: "34. Fear of not saying just the right thing  ",
    },
    {
      label: "35. Fear of losing things     ",
    },
    {
      label: "36. Intrusive (non-violent) images   ",
    },
    {
      label: "37. Intrusive nonsense sounds, words, or music   ",
    },
    {
      label: "38. Bothered by certain sounds/noises *  ",
    },
    {
      label: "39. Lucky/unlucky numbers  ",
    },
    {
      label: "40. Colors with special significance Superstitious fears",
    },
    {
      label: "41. Concern with illness or disease *  ",
    },
    {
      label:
        "42. Excessive concern with body part or aspect of appearance (e.g.  dysmorphophobia) * ",
    },
    {
      label: "43. Other",
    },
    {
      label: "44. Excessive or rituailzed handwashing ",
    },
    {
      label:
        "45. Excessive or ritualized showering, bathing, toothbrushing, grooming, or  toilet routine. Involves cleaning of household items or other inanimate objects",
    },
    {
      label:
        "46. Other measures to prevent or remove contact with contaminants ",
    },
    {
      label: "47. Other",
    },
    {
      label: "48. Checking locks, stove, appliances, etc.    ",
    },
    {
      label: "49. Cheeking that did not/will not harm others       ",
    },
    {
      label: "50. Checking that did not/will not harm self       ",
    },
    {
      label: "51. Checking that nothing terrible did/will happen       ",
    },
    {
      label: "52. Checking that did not make mistake       ",
    },
    {
      label: "53. Checking tied to somatic obsessions       ",
    },
    {
      label: "54. Other",
    },
    {
      label: "55. Re-reading or re-writing       ",
    },
    {
      label:
        "56. Need to repeat routine activities(e.g. in/out door, up/down from chair)       ",
    },
    {
      label: "57.Other",
    },
    {
      label:
        "58. [distinguish from hobbies and concern with objects of monetary or sentimental value (e.g.,carefulig reads junkmail, piles up old newspapers,  sorts through garbage, collects useless objects) ",
    },
    {
      label: "59. Excessive listmaking       ",
    },
    {
      label: "60. Need to tell, ask, or confess       ",
    },
    {
      label: "61. Need to touch, tap, or rub *       ",
    },
    {
      label: "62. Rituals involving blinking or staring *       ",
    },
    {
      label: "63. Measures (not checking) to prevent:       ",
    },
    {
      label: "64. harm to self harm to others terrible consequences       ",
    },
    {
      label: "65. Ritualized eating behaviors *       ",
    },
    {
      label: "66. Superstitious behaviors       ",
    },
    {
      label: "67. Trichotillomania *       ",
    },
    {
      label: "68. Other self damaging or self-mutilating behaviors *       ",
    },
    {
      label: "69. other",
    },
    {
      label:
        "70. How much of your time is occupied by obsessive thoughts? [When obsessions occur as brief, intermittent intrusions, it may be difficult to assess time occupied by them in temns of total hours. In such cases, estirnate time by detesmining how frequently they occur. Consider both the number of times the intrusions occur and how many hours of the day are affected. Ask:1 How frequently do the obsessive thoughts occur? [Be sure to exclude ruminations and preoccupations which, unlike obsessions, are ego-syntonic and rational (but exaggerated).]",
      helpText: `
        0 = None.<br/>
1 = Mild, less than 1 hr/day or occasional intrusion.<br/>
2 = Moderate, 1 to 3 hrs/day or frequent intrusion.<br/>
3 = Severe, greater than 3 and up to 8 hrs/day or very frequent intrusion.<br/>
4 = Extreme, greater than 8 hrs/day or near constant intrusion<br/>
        
        `,
      buttons: "0,1,2,3,4",
    },
    {
      label: `71. On the average, what is the longest number of consecutive
        waking hours per day that you are
        completely free of obsessive thoughts? [If necessary, ask:1 What
        is the longest block of time in which
        obsessive thoughts are absent?`,
      helpText: `0 = No symptoms.<br/>
        1 = Long symptom-free interval, more than 8 consecutive hours/day symptom-free.<br/>
        2 = Moderately long symptom-free interval, more than 3 and up to 8 consecutive hours/day
        symptom-free.<br/>
        3 = Short symptom-free interval, from I to 3 consecutive hours/day symptom-free.<br/>
        4 = Extremely short symptom-free interval, less than I consecutive hour/day symptom-free.
        `,
      buttons: "0,1,2,3,4",
    },
    {
      label: `72.How much do your obsessive thoughts interfere with your
social or work (or role) functioning? Is there anything that you
don't do because of them? [If currently not working determine
how much
performance would be affected if patient were employed.]`,
      helpText: `0 = None.<br/>
1 = Mild, slight interference with social or occupational activities, but overall performance not
impaired.<br/>
2 = Moderate, definite interference with social or occupational performance, but still manageable.<br/>
3 = Severe, causes substantial impairment in social or occupational performance.<br/>
4 - Extreme, incapacitating.`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `73. How much distress do your obsessive thoughts cause you?
[In most eases, distress is equated with anxiety; however,
patients may report that their obsessions are "disturbing" but
deny "anxiety." Only rate anxiety that seems triggered by
obsessions, not generalized anxiety or associated with other
conditions.]`,
      helpText: `0 = None<br/>
I = Mild, not too disturbing<br/>
2 = 1doderate, disturbing, but still manageable<br/>
3 = Severe, very disturbing<br/>
4 = Extreme, near constant and disabling distress`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `74. How much of an effort do you make to resist the obsessive
      thoughts? How often do you try to disregard or turn your anention
      away from these thoughts as they eater your mind? [Only rate
      effort made to resist, not success or failure in actually controlling
      the obsessions. How much the patient resists the obsessions may
      or may not correlate with his/her abilig to control them. Note that
      this item does not direetly measure the severig of the intrusive
      thoughts; rather it rates a manifestation of health, i.e., the effort
      the patient makes to counteract the obsessions by means other
      than avoidance or the performance of compulsions. Thus, the
      more the patient tries to resist, the less impaired is this aspect of
      his/her functioning. There are "active" and “passive" forms of
      resistance. Patients in behavioral therapy may be encouraged to
      counteract their obsessive symptoms by not struggling against
      them (e.g., "just let the thoughts come; passive opposition) or by
      intentionally bringing on the disturbing thoughts. For the purposes
      of this item, consider use of these behavioral techniques as forms
      of resistance. If the obsessions are minimal, the patieut may not
      feel the need to resist them. In such cases, a rating of "0" should
      be given.] `,
      helpText: `0 = Makes an effort to always resist, or symptoms so minimal doesn't need to actively resist<br/>
      1 = Tries to resist most of the time<br/>
      2 = Makes some effort to resist<br/>
      3 = Yields to all obsessions without attempting to control them, but does so with some reluctance<br/>
      4 = Completely and willingly yields to all obsessions`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `75. How much control do you have over your obsessive thoughts?
      How successful are you in stopping or diverting your obsessive
      thinking? Can you dismiss them? [In contrast to the preceding
      item on resistance, the ability of the patient to control his
      obsessions is more closely related to the severity of the intrusive
      thoughts.] `,
      helpText: `0 = Complete control.<br/>
      1 = Much control, usually able to stop or divert obsessions with some effort and concentration.<br/>
      2 = Moderate control, sometimes able to stop or divert obsessions.<br/>
      3 = Little control, rarely successful in stopping or dismissing obsessions, can only divert attention with
      difficulty.<br/>
      4 = No control, experienced as completely involuntary, rarely able to even momentarily alter
      obsessive thinking. `,
      buttons: "0,1,2,3,4",
    },
    {
      label: `76. How much time do you spend performing compulsive
      behaviors? [When rituals involving activities of daily living are
      chiefly present, ask:] How much longer than most people does it
      take to complete routine activities because of your rituals? [When
      compulsions occur as brief, intermittent behaviors, it may difficult
      to assess time spent performing them in terms of total hours. In
      such cases, estimate time by determining how frequently they are
      performed. Consider both the number of times compulsions are
      performed and how many hours of the day are affected. Count
      separate occurrences of compulsive behaviors, not number of
      repetitions; e.g., a patient who goes into the bathroom 20
      different times a day to wash his hands 5 times very quickly,
      performs compulsions 20 times a day, not 5 or 5 x 20 = 100.
      Ask:] How frequently do you perform compulsions? 1In most
      cases compulsions are observable behaviors(e.g., land washing),
      but some compulsions are covert (e.g., silent checking).]`,
      helpText: `0 = None<br/>
      1 = Mild (spends less than I hr/day performing compulsions), or occasional performance of compulsive
      behaviors.<br/>
      2 = Moderate (speeds from I to 3 hrs/day performing compulsions), or frequent performance of
      compulsive behaviors.<br/>
      3 = Severe (spends more than 3 and up to 8 hrs/day performing compulsions), or very frequent
      performance of compulsive behaviors.<br/>
      4 = Extreme (spends more than 8 hrs/day performing compulsions), or near constant performance of
      compulsive behaviors (too numerous to count). `,
      buttons: "0,1,2,3,4",
    },
    {
      label: `77. On the average, what is the longest number of consecutive
      waking hours per day that you are completely free of compulsive
      behavior? [If necessary, ask:] What is the longest block of time in
      which compulsions are absent?different times a day to wash his
      hands 5 times very quickly, performs compulsions 20 times a day,
      not 5 or 5 x 20 = 100. Ask:] How frequently do you perform
      compulsions? 1In most cases compulsions are observable
      behaviors(e.g., land washing), but some compulsions are covert
      (e.g., silent checking).] `,
      helpText: `0 = No symptoms.<br/>
      1 = Long symptom-free interval, more than 8 consecutive hours/day symptom-free.<br/>
      2 = Moderately long symptom-free interval, more than 3 and up to 8 consecutive hours/day
      symptom-free.<br/>
      3 = Short symptom-free interval, from I to 3 consecutive hours/day symptom-free.<br/>
      4 = Extremely short symptom-free interval, less than I consecutive hour/day symptom-free.`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `78. How much do your compulsive behaviors interfere with your
      social or work (or role) functioning? Is there anything that you
      don't do because of the compulsions? [If currently not working
      determine how much performance would be affected if patient
      were employed.]`,
      helpText: `0 = None<br/>
      1 = Mild, slight interference with social or occupational activities, but overall performance not impaired<br/>
      2 = Moderate, definite interference with social or occupational performance, but still manageable<br/>
      3 = Severe, causes substantial impaiment in social or occupational performance<br/>
      4 = Extreme, incapacitating `,
      buttons: "0,1,2,3,4",
    },
    {
      label: `79. How would you feel if prevented from performing your
      compulsion(s)? [Pause] How anxious would you become? [Rate
      degree of distress patient would experience if performance of the
      compulsion were suddenly interrupted without reassurance
      offered. In most, but not all cases, performing compulsions
      reduces anxiety. If, in the judgement of the interviewer, anxiety is
      actually reduced by preventing compulsions in the manner
      described above, then asked: How anxious do you get while
      performing compulsions until you are satisfied they are
      completed? `,
      helpText: `0 = None<br/>
      I = Mild only slightly anxious if compulsions prevented, or only slight anxiety during performance of
      compulsions<br/>
      2 = Moderate, reports that anxioty would mount but remain manageable if compulsions prevented, or
      that anxiety increases but remains manageable during performance of compulsions<br/>
      3 = Severe, prominent and very disturbing increase in anxiety if compulsions interrupted, or
      prorninent and very disturbing increase in anxiety during performance of compulsions<br/>
      4 = Extreme, incapacitating anxiety from any intervention aimed at modifying activity, or
      incapacitating anxiety develops during performance of compulsions`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `80. How much of an effort do you make to resist the compulsions?
      I Only rate effort made to resist, not success or failure in actually
      controlling the compulsions. How much the patient resists the
      compulsions may or may not correlate with his ability to control
      them. Note that this item does not directly measure the severity
      of the compulsions; rather it rates a manifestation of health, i.e.,
      the effort the patient makes to counteract the compulsions. Thus,
      the more the patient tries to resist, the less impaired is this
      aspect of his functioning. If the compulsions are minimal, the
      patient may not feel the need to resist them. In such cases, a
      rating of "0" should be given.] `,
      helpText: `0 = Malces an effort to always resist, or symptoms so minimal doesn't need to actively resist<br/>
      I = Tries to resist most of the time<br/>
      2 = Makes some effort to resist<br/>
      3 = Yields to almost all compulsions without attempting to control them, but does so with some
      reluetance<br/>
      4 = Completely and willingly yields to all compulsions`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `81. How strong is the drive to perform the compulsive behavior?
      [Pause] How much control do you have over the compulsions? [In
      contrast to the preceding item on resistance, the ability of the
      patient to control his compulsions is more closely related to the
      severity of the compulsions.]`,
      helpText: `I = Much control, experiences pressure to perform the behavior but usually able to exercise voluntary
      control over it.<br/>
      2 = Moderate control, strong pressure to perform behavior, can control it only with difficulty<br/>
      3 = Little control, very strong drive to perform behavior, must be carried to completion, can only
      delay with difficulty<br/>
      4 = No control. drive to perform behavior expericoced as completely involuntary and overpowering,
      rarely able to even momentarily delay activity`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `82. Do you think your concerns or behaviors are reasonable?
      [Pause] What do you think would happen if you did not perform
      the compulsion(s)? Are you convinced something would really
      happen? 1Ratc patient's insight into the senselessness or
      excessiveness of his obsession(s) based on beliefs expressed at
      the time of the interview.] `,
      helpText: `0 = Excellent insight, fully rational<br/>
      1 = Good insight. Readily acknowledges absurdity or excessiveness of thoughts or behaviors but does
      not seem completely convinced that there isn't something besides anxiety to be concerned about (i.e.,
      has lingering doubts).<br/>
      2 = Fair insight. Reluctantly admits thoughts or behavior seem unreasonable or excessive, but wavers.
      May have some unrealistic fears, but no fixed convictions.<br/>
      3 = Poor insight. Maintains that thoughts or behaviors are not unreasonable or excessive, but
      acknowledges validity of contrary evidence (i.e., overvalued ideas present).<br/>
      4 = Lacks insight, delusional. Definitely convinced that concerns and behavior are reasonable,
      unresponsive to contrary evidence.`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `83.Have you been avoiding doing anything, going any place, or
      being with anyone because of your obsessional thoughts or out of
      concern you will perform compulsions? [If yes, then ask:] Elow
      much do you avoid? [Rate degree to which patient deliberately
      tries to avoid things. Sometimes compulsions are designed to
      "avoid" contact with something that the patient fears. For
      example, clothes washing rituals would be designated as
      compulsions, not as avoidant behavior. If the patient stopped
      doing the laundry then this would constitute avoidance.]`,
      helpText: `0 = No deliberate avoidance<br/>
      1 = Mild, minimal avoidance<br/>
      2 = Moderate, some avoidance; clearly present<br/>
      3 = Severe, much avoidance; avoidance prominent<br/>
      4 = Extreme, very extensive avoidance; patient does almost everything he/she can to avoid triggering
      symptoms`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `84. Do you have trouble making decisions about little things that
      other people might not think twice about (e.g., which clothes to
      put on in the morning; which brand of cereal to buy)? [Exclude
      difficulty making decisions which reflect ruminative thinking.
      Ambivalence concerning rationally-based difficult choices should
      also be excluded.] `,
      helpText: `0 = None<br/>
      1 = Mild, some trouble making decisions about minor things<br/>
      2 = Moderate, freely reports significant trouble making decisions that others would not think twice
      about<br/>
      3 = Severe, continual weighing of pros and cons about nonessentials.<br/>
      4 = Extreme, unable to make any decisions. Disabling.`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `85. Do you feel very responsible for the consequences of your
      actions? Do you blame yourself for the outcome of events not
      completely in your control? [Distinguish from normal feelings of
      responsibility, feelings of worthlessness, and pathological guilt. A
      guilt-ridden person experiences himself or his actions as bad or
      evil.]`,
      helpText: `0 = None<br/>
       I = Mild, only mentioned on questioning, slight sense of over-responsibility<br/>
      2 = Moderate, ideas stated spontaneously, clearly present; patient experiences significant sense of
      over-responsibility for events outside his/her reasonable control<br/>
      3 = Severe, ideas prominent and pervasive; deeply concerned he/she is responsible for events clearly
      outside his control. Self-blaming farfetched and nearly irrational<br/>
      4 = Extreme, delusional sense of responsibility (e.g., if an earthquake occurs 3,000 miles away
      patient blames herself because she didn't perform her compulsions) `,
      buttons: "0,1,2,3,4",
    },
    {
      label: `86. Do you have difficulty starting or finishing tasks? Do many
      routine activities take longer than they should? [Distinguish from
      psychomotor retardation secondary to depression. Rate increased
      time spent performing routine activities even when specific
      obsessions cannot be identified.] `,
      helpText: `0 = None.<br/>
      I = Mild, occasional delay in starting or finishing.<br/>
      2 = Moderate, frequent prolongation of routine activities but tasks usually completed. Frequently late.<br/>
      3 = Severe, pervasive and marked difficulty initiating and completing routine tasks. Usually late.<br/>
      4 = Extreme, unable to start or complete routine tasks without full assistance.`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `87. After you complete an activity do you doubt whether you
      performed it correctly? Do you doubt whether you did it at all?
      When carrying out routine activities do you find that you don't
      trust your senses (i.e., what you see, hear, or touch)?`,
      helpText: `0 = None.<br/>
      1 = Mild, only mentioned on questioning, slight pathological doubt. Examples given may be within
      normal range.<br/>
      2 = Moderate, ideas stated spontaneously, clearly present and apparent in some of patient's
      behaviors, patient bothered by significant pathological doubt. Some effect on performance but still
      manageable.<br/>
      3 = Severe, uncertainty about perceptions or ,memory prominent; pathological doubt frequently
      affects performance.<br/>
      4 = Extreme uncertainty about perceptions constantly present; pathological doubt substantially affects
      almost all activities. Incapacitating (e.g., patient states "my mind doesn't trust what my eyes see").`,
      buttons: "0,1,2,3,4",
    },
    {
      label: `88. Interviewer's judgement of the overall severity of the patient's
      illness. Rated from O (no illness) to 6-(most severe patient seen).
      [Consider the degree of distress reported by the patient, the
      symptoms observed, and the functional impairment reported.
      Your judgement is required both in averaging this data as well as
      weighing the reliability or accuracy of the data obtained. This
      judgement is based on information obtained during the interview.]`,
      helpText: `0 = No illness<br/>
      1 = Illness slight, doubtful, transient; no functional impairment<br/>
      2 = Mild symptoms, little functional impairment<br/>
      3 = Moderate symptoms, functions with effort<br/>
      4 = Moderate - Severe symptoms, limited functioning<br/>
      5 = Severe symptoms, functions mainly with assistance<br/>
      6 = Extremely Severe symptoms, completely nonfunctional
      `,
      buttons: "0,1,2,3,4,5,6",
    },
    {
      label: `89. Rate total overall improvement present SINCE THE INITIAL
      RATING whether or not, in your judgement, it is due to drug
      treatment. `,
      helpText: `0 = Very much worse<br/>
      1 = Much worse<br/>
      2 = Minimal worse<br/>
      3 = No change<br/>
      4 = Minimally improved<br/>
      5 = Much improved<br/>
      6 = Very much improved`,
      buttons: "0,1,2,3,4,5,6",
    },
    {
      label: `90. Rate the overall reliability of the rating scores obtained. Factors
      that may affect reliability include the patient's cooperativenes and
      his/her natural ability to communicate. The type and severity of
      obsessive-compulsive symptoms present may interfere with the
      patient's concentration, attention, or freedom to speak
      spontaneously (e.g., the content of some obsessions may cause
      the patient to choose his words very carefully). 
      `,
      helpText: `0 = Excellent, no reason to suspect data unreliable<br/>
      1 = Good, factor(s) present that may adversely affect reliability<br/>
      2 = Fair, factorts) present that definitely reduce reliability<br/>
      3 = Poor, very low reliability`,
      buttons: "0,1,2,3",
    },
  ];
  //   const initialStepLabels = [
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //     { label: "" },
  //   ];
  //   const styleConfig = { size: "50px" };
  //   const connectorStyleConfig = { stepSize: "10px" };
  //   const stepss = {
  //     marginRight: "100px",
  //     marginLeft: "80px",
  //     padding: "100px",
  //   };

  const MyComponent = (props) => {
    console.log(props.title, 'in my props')
    function CallMe() {
      if (active == 0) setVerticalSteps(0);
      if (active == 9) setVerticalSteps(1);
      if (active == 19) setVerticalSteps(2);
      if (active == 24) setVerticalSteps(3);
      if (active == 25) setVerticalSteps(4);
      if (active == 28) setVerticalSteps(5);
      if (active == 30) setVerticalSteps(6);
      if (active == 39) setVerticalSteps(7);
      if (active == 42) setVerticalSteps(8);
      if (active == 46) setVerticalSteps(9);
      if (active == 53) setVerticalSteps(10);
      if (active == 56) setVerticalSteps(11);
      if (active == 57) setVerticalSteps(12);
      if (active == 68) setVerticalSteps(13);
      if (active == 69) setVerticalSteps(14);
      if (active == 70) setVerticalSteps(15);
      if (active == 71) setVerticalSteps(16);
      if (active == 72) setVerticalSteps(17);
      if (active == 73) setVerticalSteps(18);
      if (active == 74) setVerticalSteps(19);
      if (active == 75) setVerticalSteps(20);
      if (active == 76) setVerticalSteps(21);
      if (active == 77) setVerticalSteps(22);
      if (active == 78) setVerticalSteps(23);
      if (active == 79) setVerticalSteps(24);
      if (active == 80) setVerticalSteps(25);
      if (active == 81) setVerticalSteps(26);
      if (active == 82) setVerticalSteps(27);
      if (active == 83) setVerticalSteps(28);
      if (active == 84) setVerticalSteps(29);
      if (active == 85) setVerticalSteps(30);
      if (active == 86) setVerticalSteps(31);
      if (active == 87) setVerticalSteps(32);
      if (active == 88) setVerticalSteps(33);
      if (active == 89) setVerticalSteps(34);
    }

    // console.log("Props k bhai k asnde:", props.title.buttons);
    // debugger
    var buttonsSlipter = props.title.buttons?.split(",");
    console.log("Props k bhai k asnde:", props.title.label);
    console.log("Props k bhai k asnde1:", props.title.buttons);
    return !props.title ? null : (
      <div>
        <Row>
          <label style={{ fontWeight: "bold" }}>{props.title.label}</label>
        </Row>

        <Stack spacing={2} direction="row" marginLeft={10} marginTop={3}>
          {buttonsSlipter ? (
            buttonsSlipter.map((step) => (
              <Button
                variant="contained"
                size="lg"
                style={{ backgroundColor: "#FCBE13" }}
                onClick={() => {
                  CallMe();
                  updateStep(active + 1, 1);
                }}
              >
                {step}
              </Button>
            ))
          ) : (
            <>
              <Button
                variant="contained"
                size="lg"
                style={{ backgroundColor: "#FCBE13" }}
                onClick={() => {
                  CallMe();
                  updateStep(active + 1, 1);
                }}
              >
                Current
              </Button>
              <Button
                size="lg"
                style={{ backgroundColor: "#FCBE13" }}
                variant="contained"
                onClick={() => {
                  CallMe();
                  updateStep(active + 1, 0);
                }}
              >
                Past
              </Button>
            </>
          )}
        </Stack>
        <Row>
          {props.title.helpText ? (
            <div dangerouslySetInnerHTML={{ __html: props.title.helpText }} />
          ) : null}
        </Row>
      </div>
    );
  };

  return (<>  <Modal
    style={{ paddingTop: "75px", textAlign: "center" }}
    show={modalVisible}
    onHide={handleClose}
  >
    <Modal.Header closeButton style={{ padding: "5px" }}>
      <Modal.Title
        style={{ color: "#31A5D7", fontWeight: "bold" }}
      >
        Doing This Together
      </Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ fontSize: "25px" }}>
    Great job!<span style={{color:'yellow'}}> &#9733;</span> You're all done. Thanks for completing the questions!
    </Modal.Body>
    {/* <Modal.Footer style={{ padding: "0px" }}>
      <Container style={{ padding: "0px" }}>
        <Row>
          <Col xs={4} md={5} lg={5}></Col>
          <Col
            xs={2}
            md={1}
            lg={1}
            style={{ margin: "3px", textAlign: "center" }}
          >
            <Button
              style={{ backgroundColor: "#31A5D7" }}
              onClick={handleClose}
            >
              No
            </Button>{" "}
          </Col>
          <Col
            xs={2}
            md={1}
            lg={1}
            style={{ margin: "3px", textAlign: "center" }}
          >
            <Button
              style={{ backgroundColor: "#31A5D7" }}
              // variant="primary"
              onClick={() => {
                setModalVisible(false);
                // history.push("/Home");
                window.location.href =
                  "https://suicidepreventionlifeline.org";
              }}
            >
              Yes
            </Button>{" "}
          </Col>
          <Col xs={4} md={5} lg={5}></Col>
        </Row>
      </Container>
    </Modal.Footer> */}
  </Modal>
    // Vertical Stepper code start
    <Box>

      <Row>
        <h1 style={{ textAlign: "center", fontWeight: 600, marginTop: 20 }}>
          YALE-BROWN OBSESSIVE COMPULSIVE SCALE (Y-BOCS)
        </h1>
      </Row>
      <Row>
        <h3 style={{ textAlign: "center", fontWeight: 500, marginTop: 10 }}>
          Instructions: Please answer each question to the best of your ability
        </h3>
      </Row>
      <Box style={{ padding: 70, paddingTop: 10 }}>
        <ThemeProvider theme={theme}>
          <Stepper activeStep={mainSteps} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>
                  <h3>{step.label}</h3>
                </StepLabel>
                <StepContent>
                  <Row xs={12} sm={12} md={12} lg={12}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <MyComponent
                        title={active <= 90 ? initialStep[active] : null}
                      />
                    </Col>
                  </Row>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
        {/* {active === initialStep.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
          </Paper>
        )} */}
      </Box>
    </Box>
    </>
   
  );
}
