import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { createBrowserHistory } from "history";
import Stepper from "react-stepper-horizontal";
import { BsFillReplyAllFill } from "react-icons/bs";

export default function PHQ() {
  const [date, setDate] = useState(new Date(1598051730000));
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [active, setActive] = useState(0);
  const [q1, setQ1] = useState(0);
  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);
  const [q5, setQ5] = useState(0);
  const [q6, setQ6] = useState(0);
  const [q7, setQ7] = useState(0);
  const [q8, setQ8] = useState(0);
  const [q9, setQ9] = useState(null);
  const [score, setScore] = useState(0);
  const [patientId, setpatientId] = useState(localStorage.getItem("dtt_PatientId")); 
  const [modalVisible, setModalVisible] = useState(false);

  const handleClose = () => {
    setModalVisible(false);
    history.push("/Home");
  };
  const history = createBrowserHistory({ forceRefresh: true });
  const navigation = useNavigate();
  const token = `${localStorage.getItem("dtt_PatientToken")}`;
  function updateStep(step, newscore = 0) {
    // this.myStepper.setActive(5)
    onNextStep();
    // setActive(step)
    console.log("step: ", step);
    // onNextStep(step)
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) setQ7(newscore);
    if (step === 8) setQ8(newscore);
    if (step === 9) {
      setQ9(newscore);

      // saveGAD7Result();
    }
    setScore(score + newscore);
  }
  function previousStep(step, newscore = 0) {
    // this.myStepper.setActive(5)
    onPreviousStep();
    // setActive(step)
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) {
      setQ7(newscore);
    }
    setScore(score + newscore);
  }
  useEffect(() => {
    if (q9 != null)
      // console.log("useEffect ID: ", q9);
      saveGAD7Result();
  }, [q9]);
  async function saveGAD7Result() {
    // setActive(0)
    // const patientId = await AsyncStorage.getItem('dtt_user_id')
    // console.log('Patient ID: ', patientId)
    const postData = {
      q1: q1,
      q2: q2,
      q3: q3,
      q4: q4,
      q5: q5,
      q6: q6,
      q7: q7,
      q8: q8,
      q9: q9,
      totalScore: score,
      source: "PatientPortalWeb",
      patientId: patientId,
    };

    console.log("PostData: ", postData);
    // console.log('axiosConfig: ', axiosConfig)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/addPatientPHQ9`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(score);
        // setActive(0)
        // setScore(0);
        if (q9 > 0) {
          setModalVisible(!modalVisible);
        } else {
          history.push("/Home");
        }
      });
  }
  const progressStepsStyle = {
    completedStepIconColor: "#686868",
    completedProgressBarColor: "#686868",
    completedCheckColor: "#4bb543",
  };
  // defaultScrollViewProps = {
  //   keyboardShouldPersistTaps: 'handled',
  //   contentContainerStyle: {
  //     flex: 1,
  //     justifyContent: 'center',
  //   },
  // }
  const buttonTextStyle = {
    // color: '#FCBE13',
    fontWeight: "bold",
  };
  function onNextStep() {
    setActive(active + 1);
    console.log("called next step:", active);
  }
  function onPreviousStep() {
    setActive(active - 1);
    console.log("called Previous step:", active);
  }
  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;

    setDate(currentDate);
  };

  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode("date");
  };

  const showTimepicker = () => {
    showMode("time");
  };
  const initialStep = [
    { label: "Little interest or pleasure in doing things" },
    { label: "Feeling down, depressed, or hopeless." },
    { label: "Trouble falling or staying asleep, or sleeping too much." },
    { label: "Feeling tired or having little energy." },
    { label: "Poor appetite or overeating." },
    {
      label:
        "Feeling bad about yourself - or that you are a failure or have let yourself or your family down.",
    },
    {
      label:
        "Trouble concentrating on things, such as reading the newspaper or watching television.",
    },
    {
      label:
        "Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual.",
    },
    {
      label:
        "Thoughts that you would be better off dead, or of hurting yourself in some way.",
    },
  ];
  const initialStepLabels = [
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
  ];
  const styleConfig = { size: "50px" };
  const connectorStyleConfig = { stepSize: "10px" };
  const stepss = {
    // max-width: 90%;
    marginRight: "100px",
    marginLeft: "80px",
    padding: "100px",
  };

  const MyComponent = (props) => {
    console.log("Props:", props);
    return !props.title ? null : (
      <div>
        <Row>
          <label style={{ fontWeight: "bold" }}>{props.title.label}</label>
        </Row>
        <Row>
          <Col sm={4}></Col>
          <Col sm={4}>
            <Row style={{ marginBottom: "10px" }}>
              <Button
                size="lg"
                style={{ backgroundColor: "#FCBE13" }}
                // mode="contained"
                onClick={() => updateStep(active + 1, 0)}
              >
                Not at all
              </Button>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Button
                size="lg"
                style={{ backgroundColor: "#FCBE13" }}
                mode="contained"
                onClick={() => updateStep(active + 1, 1)}
              >
                Several days
              </Button>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Button
                style={{ backgroundColor: "#FCBE13" }}
                size="lg"
                mode="contained"
                onClick={() => updateStep(active + 1, 2)}
              >
                More than half the days
              </Button>
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Button
                size="lg"
                style={{ backgroundColor: "#FCBE13" }}
                mode="contained"
                onClick={() => updateStep(active + 1, 3)}
              >
                Nearly every day
              </Button>
            </Row>
          </Col>
          <Col sm={4}></Col>
        </Row>
        {active > 0 ? (
          <Button onClick={() => previousStep(active - 1)}>
            <BsFillReplyAllFill style={{ marginBottom: "5px" }} /> Previous
          </Button>
        ) : (
          ""
        )}
      </div>
    );
  };
  return (
    <Container style={{ maxWidth: "100%" }}>
      <Row xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Row>
            <h1 style={{ textAlign: "center" }}>PHQ9 </h1>
          </Row>

          <Stepper
            steps={initialStepLabels}
            activeStep={active}
            activeColor={"#CB7723"}
            completeColor={"#2EA4D6"}
          />

          <MyComponent title={active < 10 ? initialStep[active] : null} />

          <Modal 
            style={{ paddingTop: "75px",textAlign:'center', }}
            show={modalVisible}
            onHide={handleClose}
          >
            <Modal.Header closeButton  style={{padding:'5px'}}>
              <Modal.Title style={{color: "#31A5D7",fontWeight:'bold'}}>Doing This Together</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{fontSize:"25px"}}>I'm having thoughts of hurting myself!</Modal.Body>
            <Modal.Footer style={{padding:'0px'}}>
              
              <Container style={{padding:'0px'}}>
              <Row>
            <Col xs={4} md={5} lg={5}>
            
            </Col>
            <Col xs={2} md={1} lg={1} style={{margin:'3px',textAlign:'center', }} >
            <Button  style={{backgroundColor: "#31A5D7",}} onClick={handleClose}>
                No
              </Button>            </Col>
            <Col xs={2} md={1} lg={1} style={{margin:'3px',textAlign:'center', }}>
            <Button  style={{backgroundColor: "#31A5D7",}}
                // variant="primary"
                onClick={() => {
                  setModalVisible(false);
                   history.push("/Home");
                   window.location.href =
                    "https://suicidepreventionlifeline.org";
                }}
              >
                Yes
              </Button>            </Col>
            <Col xs={4} md={5} lg={5}>
              
            </Col>
          </Row>
        </Container>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}
