import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { createBrowserHistory } from "history";
// import Stepper from "react-stepper-horizontal";
import { BsFillReplyAllFill } from "react-icons/bs";

// MUI Stepper imports
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

// Vertical Stepper code start
const steps = [
  {
    label:
      " Has there ever been a period of time when you were not your usual self and...",
  },
];
// Vertical Stepper code end

export default function Mdq() {
  // Vertical Stepper code start
  const [mainSteps, setVerticalSteps] = React.useState(0);
  const handleNext = () => {
    setVerticalSteps((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setVerticalSteps((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setVerticalSteps(0);
  };
  // Vertical Stepper code end

  const [date, setDate] = useState(new Date());
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [active, setActive] = useState(0);
  const [q1, setQ1] = useState(0);
  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);
  const [q5, setQ5] = useState(0);
  const [q6, setQ6] = useState(0);
  const [q7, setQ7] = useState(0);
  const [q8, setQ8] = useState(0);
  const [q9, setQ9] = useState(0);
  const [q10, setQ10] = useState(0);
  const [q11, setQ11] = useState(0);
  const [q12, setQ12] = useState(0);
  const [q13, setQ13] = useState(0);
  const [q14, setQ14] = useState(0);
  const [q15, setQ15] = useState(0);
  const [q16, setQ16] = useState(0);
  const [q17, setQ17] = useState(null);
  const [score, setScore] = useState(0);
  const [patientId, setpatientId] = useState(
    localStorage.getItem("dtt_PatientId")
  );
  const [modalVisible, setModalVisible] = useState(false);
  const handleClose = () => {
    setModalVisible(false);
    history.push("/Home");
  };
  const history = createBrowserHistory({ forceRefresh: true });
  const navigation = useNavigate();
  const token = `${localStorage.getItem("dtt_PatientToken")}`;
  function updateStep(step, newscore = 0) {
    onNextStep();
    console.log("step: ", step);
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) setQ7(newscore);
    if (step === 8) setQ8(newscore);
    if (step === 9) setQ9(newscore);
    if (step === 10) setQ10(newscore);
    if (step === 11) setQ11(newscore);
    if (step === 12) setQ12(newscore);
    if (step === 13) setQ13(newscore);
    if (step === 14) setQ14(newscore);
    if (step === 15) setQ15(newscore);
    if (step === 16) setQ16(newscore);
    if (step === 17) {
      setQ17(newscore);
    }
    setScore(score + newscore);
  }
  function previousStep(step, newscore = 0) {
    onPreviousStep();
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) setQ7(newscore);
    if (step === 8) setQ8(newscore);
    if (step === 9) setQ9(newscore);
    if (step === 10) setQ10(newscore);
    if (step === 11) setQ11(newscore);
    if (step === 12) setQ12(newscore);
    if (step === 13) setQ13(newscore);
    if (step === 14) setQ14(newscore);
    if (step === 15) setQ15(newscore);
    if (step === 16) setQ16(newscore);
    if (step === 17) {
      setQ17(newscore);
    }
    setScore(score + newscore);
  }
  useEffect(() => {
    if (q17 != null) saveGAD7Result();
  }, [q17]);
  async function saveGAD7Result() {
    const postData = {
      q1: q1,
      q2: q2,
      q3: q3,
      q4: q4,
      q5: q5,
      q6: q6,
      q7: q7,
      q8: q8,
      q9: q9,
      q10: q10,
      q11: q11,
      q13: q13,
      q14: q14,
      q15: q15,
      q16: q16,
      q17: q17,
      totalScore: score,
      source: "PatientPortalWeb",
      patientId: patientId,
      date:date
    };
    console.log("PostData: ", postData);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/addMdq17`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(score);
        if (response.data.id > 0) {
          setModalVisible(!modalVisible);
          setTimeout(() => {
            history.push("/Home");
          }, 5000); // 5000 milliseconds = 5 seconds
        } else {
          // history.push("/Home");
        }
      });
  }
  const progressStepsStyle = {
    completedStepIconColor: "#686868",
    completedProgressBarColor: "#686868",
    completedCheckColor: "#4bb543",
  };
  const buttonTextStyle = {
    fontWeight: "bold",
  };
  function onNextStep() {
    setActive(active + 1);
    console.log("called next step:", active);
  }
  function onPreviousStep() {
    setActive(active - 1);
    console.log("called Previous step:", active);
  }
  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate !== null ? selectedDate : new Date();
  
    setDate(currentDate);
  };
  

  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  const showDatepicker = () => {
    showMode("date");
  };

  const showTimepicker = () => {
    showMode("time");
  };
  const initialStep = [
    {
      label:
        "1. you felt so good or so hyper that other people thought you were not your normal self or you were so hyper that you got into trouble?",
    },
    {
      label:
        "2. you were so irritable that you shouted at people or started fights or arguments?",
    },
    {
      label: "3. you felt much more self-confident than usual?",
    },
    {
      label:
        "4. you got much less sleep than usual and found you didn’t really miss it?",
    },
    {
      label: "5. you were much more talkative or spoke much faster than usual?",
    },
    {
      label:
        "6. thoughts raced through your head or you couldn’t slow your mind down?",
    },
    {
      label:
        "7. you were so easily distracted by things around you that you had trouble concentrating or staying on track?",
    },
    {
      label: "8. you had much more energy than usual?",
    },
    {
      label:
        "9. you were much more active or did many more things than usual? ",
    },
    {
      label:
        "10. you were much more social or outgoing than usual, for example, you telephoned friends in the middle of the night?",
    },
    {
      label: "11. you were much more interested in sex than usual?",
    },
    {
      label:
        "12. you did things that were unusual for you or that other people might have thought were excessive, foolish, or risky?",
    },
    {
      label: "13. spending money got you or your family into trouble?",
    },
    {
      label:
        "14. If you checked YES to more than one of the above, have several of these ever happened during the same period of time?",
    },
    {
      label:
        "15. How much of a problem did any of these cause you – like being unable to work, having family, money or legal troubles; getting into arguments or fights?  Please circle one response only",
    },
    {
      label:
        "16. Have any of your blood relatives (i.e. children, siblings, parents, grandparents, aunts, uncles) had manic-depressive illness or bipolar disorder?",
    },
    {
      label:
        "17. Have any of your blood relatives (i.e. children, siblings, parents, grandparents, aunts, uncles) had manic-depressive illness or bipolar disorder?",
    },
  ];
  const styleConfig = { size: "50px" };
  const connectorStyleConfig = { stepSize: "10px" };
  const stepss = {
    // max-width: 90%;
    marginRight: "100px",
    marginLeft: "80px",
    padding: "100px",
  };

  const MyComponent = (props) => {
    console.log("Props:", props);
    return !props.title ? null : (
      <div>
        <Row>
          <label style={{ fontWeight: "bold" }}>{props.title.label}</label>
        </Row>
        <Stack spacing={2} direction="row" marginLeft={10} marginTop={3}>
          <Button
            variant="contained"
            size="lg"
            style={{ backgroundColor: "#FCBE13" }}
            onClick={() => {
              //   if (active == 0) setVerticalSteps(0);
              updateStep(active + 1, 1);
            }}
          >
            Yes
          </Button>
          <Button
            size="lg"
            style={{ backgroundColor: "#FCBE13" }}
            variant="contained"
            onClick={() => {
              //   if (active == 0) setVerticalSteps(0);
              updateStep(active + 1, 0);
            }}
          >
            No
          </Button>
        </Stack>
      </div>
    );
  };
  return (
    // Vertical Stepper code start
    <Box>
      <Row>
        <h1 style={{ textAlign: "center", fontWeight: 600, marginTop: 20 }}>
          The Mood Disorder Questionnaire
        </h1>
      </Row>
      <Row>
        <h3 style={{ textAlign: "center", fontWeight: 500, marginTop: 10 }}>
          Instructions: Please answer each question to the best of your ability
        </h3>
      </Row>
      <Box style={{ padding: 70, paddingTop: 10 }}>
        <Stepper activeStep={mainSteps} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
              // optional={
              //   index === 2 ? (
              //     <Typography variant="caption">Last step</Typography>
              //   ) : null
              // }
              >
                <h3>{step.label}</h3>
              </StepLabel>
              <StepContent>
                {/* <Typography>{step.description}</Typography> */}
                <Row xs={12} sm={12} md={12} lg={12}>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <MyComponent
                      title={active < 18 ? initialStep[active] : null}
                    />
                      <Modal
                    style={{ paddingTop: "75px", textAlign: "center" }}
                    show={modalVisible}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton style={{ padding: "5px" }}>
                      <Modal.Title
                        style={{ color: "#31A5D7", fontWeight: "bold" }}
                      >
                        Doing This Together
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ fontSize: "25px" }}>
                    Great job!<span style={{color:'yellow'}}> &#9733;</span> You're all done. Thanks for completing the questions!
                    </Modal.Body>
                    {/* <Modal.Footer style={{ padding: "0px" }}>
                      <Container style={{ padding: "0px" }}>
                        <Row>
                          <Col xs={4} md={5} lg={5}></Col>
                          <Col
                            xs={2}
                            md={1}
                            lg={1}
                            style={{ margin: "3px", textAlign: "center" }}
                          >
                            <Button
                              style={{ backgroundColor: "#31A5D7" }}
                              onClick={handleClose}
                            >
                              No
                            </Button>{" "}
                          </Col>
                          <Col
                            xs={2}
                            md={1}
                            lg={1}
                            style={{ margin: "3px", textAlign: "center" }}
                          >
                            <Button
                              style={{ backgroundColor: "#31A5D7" }}
                              // variant="primary"
                              onClick={() => {
                                setModalVisible(false);
                                // history.push("/Home");
                                window.location.href =
                                  "https://suicidepreventionlifeline.org";
                              }}
                            >
                              Yes
                            </Button>{" "}
                          </Col>
                          <Col xs={4} md={5} lg={5}></Col>
                        </Row>
                      </Container>
                    </Modal.Footer> */}
                  </Modal>
                    {/* <Modal
                    style={{ paddingTop: "75px", textAlign: "center" }}
                    show={modalVisible}
                    onHide={handleClose}
                  >
                    <Modal.Header closeButton style={{ padding: "5px" }}>
                      <Modal.Title
                        style={{ color: "#31A5D7", fontWeight: "bold" }}
                      >
                        Doing This Together
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ fontSize: "25px" }}>
                      I'm having thoughts of hurting myself!
                    </Modal.Body>
                    <Modal.Footer style={{ padding: "0px" }}>
                      <Container style={{ padding: "0px" }}>
                        <Row>
                          <Col xs={4} md={5} lg={5}></Col>
                          <Col
                            xs={2}
                            md={1}
                            lg={1}
                            style={{ margin: "3px", textAlign: "center" }}
                          >
                            <Button
                              style={{ backgroundColor: "#31A5D7" }}
                              onClick={handleClose}
                            >
                              No
                            </Button>{" "}
                          </Col>
                          <Col
                            xs={2}
                            md={1}
                            lg={1}
                            style={{ margin: "3px", textAlign: "center" }}
                          >
                            <Button
                              style={{ backgroundColor: "#31A5D7" }}
                              // variant="primary"
                              onClick={() => {
                                setModalVisible(false);
                                history.push("/Home");
                                window.location.href =
                                  "https://suicidepreventionlifeline.org";
                              }}
                            >
                              Yes
                            </Button>{" "}
                          </Col>
                          <Col xs={4} md={5} lg={5}></Col>
                        </Row>
                      </Container>
                    </Modal.Footer>
                  </Modal> */}
                  </Col>
                </Row>
                {/* <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {/* {active === initialStep.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
          </Paper>
        )} */}
      </Box>
    </Box>

    // Vertical Stepper code end
  );
}
