import React from "react";
import ReactDOM from "react-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/logoMobilecopy.png";
import add from "../assets/add.png";
import deletee from "../assets/delete.png";
import Chart from "react-google-charts";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { BiPlusMedical } from "react-icons/bi";
const history = createBrowserHistory({ forceRefresh: true });
export default function Videos() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
        <Container
        style={{ maxWidth: "100%" }}
      >
        <Row>
          {/* <Col sm={2}></Col> */}
          <Col sm={12} className="text-center">
          <label
            style={{
              padding: "10px",
              fontWeight: "bold",
              fontSize: "30px",
              color: "#31A5D7",
            }}
          >
           Collaborative Care Medicine Introduction Video{" "}
          </label>
            
            {/* <Button
            className="Button"
            type="submit"
            style={{
              borderRadius: 5,
              marginTop: 10,
              color: "black",
              fontWeight: "bold",
              width: "100%",
              marginBottom: 10,
              backgroundColor: "#FCBE13",
            }}
             onClick={togglePlay}
             >
                        {isPlaying ? "COCM Video Pause" : "COCM Video Play"}
                    
      </Button> */}
          </Col>
          {/* <Col sm={3}></Col> */}
        </Row>
      </Container>
      <Container>
      <Row >
        <Col sm={12} md={2} lg={3}></Col>
        <Col sm={12} md={8} lg={6}>
        <video ref={videoRef} width="100%" height="400px" onClick={togglePlay} controls>
                        
        <source
        src= {`${process.env.REACT_APP_Patient_Documents}/LearningDocs/videos/cocm.mp4`}
          // src="http://patient.doingthistogether.com/LearningDocs/videos/cocm.mp4"
          type="video/mp4"
        />            
      </video>
        </Col>
        <Col sm={12} md={2} lg={3}></Col>
        </Row>
      </Container>
                               
      {/* <Button onClick={togglePlay}>
                        {isPlaying ? "Pause" : "Play"}
                    
      </Button> */}
     
              
    </div>
  );
  
}
