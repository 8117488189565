import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LearningDocsCard from "../components/LearningDocsCard";
// import { theme } from '../core/theme'
// import { process.env.REACT_APP_Patient_Documents } from '@env'
// import SelectDropdown from 'react-native-select-dropdown'

// import logo from "..";
export default function LearningDoc({ navigation }) {
  return (
    <Container>
      <Row xs={12} sm={12} md={12} lg={12}>
        {/* left side side extra */}
        {/* <Col xs={0} sm={0} md={3} lg={0}></Col> */}
        <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Depression"}
            description={"Depression"}
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_depression.png`}
            list={[
              {
                name: "Chronic Illness and Mental Health Recognizing and Treating Depression",
                description:
                  "Chronic Illness and Mental Health Recognizing and Treating Depression",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Chronic Illness and Mental Health Recognizing and Treating Depression.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_depression1.png`,
              },
              {
                name: "Depression in Women 5 Things You Should Know",
                description: "Depression in Women 5 Things You Should Know",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Depression in Women.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d11.png`,
              },
              {
                name: "Depression",
                description: "Depression",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Depression.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_z8.png`,
              },
              {
                name: "Men and Depression",
                description: "Men and Depression",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Men and Depression.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_b2.png`,
              },
              {
                name: "Older Adults and Depression",
                description: "Older Adults and Depression",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Older Adults and Depression.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_b3.png`,
              },
              {
                name: "Perinatal Depression",
                description: "Perinatal Depression",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Perinatal Depression.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c4.png`,
              },
              {
                name: "Seasonal Affective Disorder More Than the Winter Blues (Infographic)",
                description:
                  "Seasonal Affective Disorder More Than the Winter Blues (Infographic)",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Seasonal Affective Disorder More Than the Winter Blues Infographic.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_p1.png`,
              },
              {
                name: "Seasonal Affective Disorder",
                description: "Seasonal Affective Disorder",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Seasonal Affective Disorder.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c46.png`,
              },
              {
                name: "Teen Depression More Than Just Moodiness (Infographic)",
                description:
                  "Teen Depression More Than Just Moodiness (Infographic)",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Teen Depression More Than Just Moodiness.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a29.png`,
              },
            ]}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Anxiety Disorders"}
            description={
              "Generalized Anxiety Disorder, When Worry Gets Out of Control"
            }
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d1.png`}
            // localImage={'New_a1.png'}
            list={[
              {
                name: "Generalized Anxiety Disorder, When Worry Gets Out of Control",
                description:
                  "Generalized Anxiety Disorder When Worry Gets Out of Control",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Generalized Anxiety Disorder When Worry Gets Out of Control.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_b1.png`,
              },
              {
                name: " I'm So Stress Out! (Infographic)",
                description: " I'm So Stress Out! (Infographic)",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/I am So Stress Out Infographic.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a7.png`,
              },
              {
                name: " I'm So Stressed Out!",
                description: " I'm So Stressed Out!",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/I am So Stressed Out.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/stressout.png`,
              },
              {
                name: "Panic Disorder When Fear Overwhelms",
                description: "Panic Disorder When Fear Overwhelms",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Panic Disorder When Fear Overwhelms.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d8.png`,
              },
              {
                name: "Social Anxiety Disorder, More Than Just Shyness",
                description: "Social Anxiety Disorder, More Than Just Shyness",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Social Anxiety Disorder More Than Just Shyness.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d9.png`,
              },
              {
                name: "Stand Up To Stress - A Coloring, Activity Book for Kids Ages 8 to 12",
                description:
                  "Stand Up To Stress - A Coloring, Activity Book for Kids Ages 8 to 12 ",
               file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Stand Up To Stress A Coloring _ Activity Book For Kids Ages 8 to 12.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_bb.png`,
              },
            ]}
          />
        </Col>
        {/* right side extra */}
        {/*   <Col xs={0} sm={0} md={3} lg={0}></Col> */}
      </Row>
      <Row xs={12} sm={12} md={12} lg={12}>
      <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Stress"}
            description={"Stress"}
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a7.png`}
            list={[
              {
                name: " I'm So Stressed Out! (Infographic)",
                description: " I'm So Stressed Out! (Infographic)",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/I am So Stress Out Infographic.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a7.png`,
              },
              {
                name: " I'm So Stressed Out!",
                description: " I'm So Stressed Out!",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/I am So Stressed Out.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/stressout.png`,
              },
              {
                name: "My Mental Health Do I Need Help",
                description: "My Mental Health Do I Need Help",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/My Mental Health Do I Need Help.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/mentalhealth.png`,
              },
              {
                name: "Stand Up to Stress! A Coloring and Activity Book",
                description: "Stand Up to Stress! A Coloring and Activity Book",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Stand Up To Stress A Coloring _ Activity Book For Kids Ages 8 to 12.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_bb.png`,
              },
              {
                name: "Stress Catcher Catch Some Great Coping Strategies and Skills for Managing Stress",
                description:
                  "Stress Catcher Catch Some Great Coping Strategies and Skills for Managing Stress",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Stress Catcher Catch Some Great Coping Strategies and Skills for Managing Stress.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/stresscatcher.png`,
              },
            ]}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Bipolar Disorder"}
            description={
              "Do you feel very happy and outgoing—or very irritable—on some days....."
            }
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_bipolar.png`}
            list={[
              {
                name: "Bipolar Disorder",
                description:
                  "Do you feel very happy and outgoing—or very irritable—on some days, but unusually sad or anxious on other days? Do the “up” periods go along with increased energy or activity?.........",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Bipolar Disorder.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d12.png`,
              },
              {
                name: "Bipolar Disorder in Teens and Young Adults Know the Signs (Infographic)",
                description:
                  "Bipolar Disorder in Teens and Young Adults Know the Signs (Infographic)",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Bipolar Disorder in Teens and Young Adults Know the Signs (Infographic).html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a13a.png`,
              },
              {
                name: "Bipolar Disorder in Children and Teens",
                description: "Bipolar Disorder in Children and Teens",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Bipolar Disorder in Children and Teens.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d7.png`,
              },
            ]}
          />
        </Col>
       
      </Row>
      <Row xs={12} sm={12} md={12} lg={12}>
      <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Post Traumatic Stress Disorder"}
            description={"Post Traumatic Stress Disorder"}
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c15.png`}
            list={[
              {
                name: "Post Traumatic Stress Disorder",
                description: "Post Traumatic Stress Disorder",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Post Traumatic Stress Disorder.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c15.png`,
              },
            ]}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Suicide"}
            description={"Suicide"}
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c16.png`}
            list={[
              {
                name: "Five Action Steps for Helping Someone in Emotional Pain",
                description:
                  "Five Action Steps for Helping Someone in Emotional Pain",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Five Action Steps for Helping Someone in Emotional Pain.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_steps.png`,
              },
              {
                name: "Frequently Asked Questions About Suicide",
                description: "Frequently Asked Questions About Suicide",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Frequently Asked Questions About Suicide.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c2.png`,
              },
              {
                name: "Warning Signs Of Suicide",
                description: "Warning Signs Of Suicide",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Warning Signs Of Suicide.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c26.png`,
              },
            ]}
          />
        </Col>
       
      </Row>
      <Row xs={12} sm={12} md={12} lg={12}>
        <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Obsessive Compulsive Disorder"}
            description={"Obsessive Compulsive Disorder"}
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c14.png`}
            list={[
              {
                name: "Obsessive Compulsive Disorder When Unwanted Thoughts or Repetitive Behaviors Take Over",
                description:
                  "Obsessive Compulsive Disorder When Unwanted Thoughts or Repetitive Behaviors Take Over",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Obsessive Compulsive Disorder When Unwanted Thoughts or Repetitive Behaviors Take Over.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c14.png`,
              },
            ]}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Eating Disorders"}
            description={"Eating Disorders"}
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logoeating.png`}
            list={[
              {
                name: "Let_s Talk About Eating Disorders",
                description: "Let_s Talk About Eating Disorders",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Let_s Talk About Eating Disorders.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c6.png`,
              },
              {
                name: "Eating Disorders About More Than Food",
                description: "Eating Disorders About More Than Food",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Eating Disorders About More Than Food.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c17.png`,
              },
            ]}
          />
        </Col>
      </Row>
      
      <Row xs={12} sm={12} md={12} lg={12}>
      <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Attention Deficit Hyperactivity Disorder"}
            description={"Attention Deficit Hyperactivity Disorder in Adults"}
            // localImage={'New.png'}
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo.png`}
            list={[
              {
                name: "Attention Deficit Hyperactivity Disorder in Adults",
                description:
                  "Attention Deficit Hyperactivity Disorder in Adults What You Need to Know",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Attention Deficit Hyperactivity Adults.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo.png`,
              },
            ]}
          />
        </Col>
      <Col xs={12} sm={12} md={6} lg={6}>
          <LearningDocsCard
            name={"Children and Adolescents"}
            description={"Children and Adolescents"}
            mainImage={`${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d3.png`}
            list={[
              {
                name: "Attention Deficit Hyperactivity Disorder in Children and Teens What You Need to Know",
                description:
                  "Attention Deficit Hyperactivity Disorder in Children and Teens What You Need to Know",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Attention Deficit Hyperactivity  Children and Teens .html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d3.png`,
              },
              {
                name: "Autism Spectrum Disorder",
                description: "Autism Spectrum Disorder",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Autism Spectrum Disorder.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d4.png`,
              },
              {
                name: "Bipolar Disorder in Children and Teens",
                description: "Bipolar Disorder in Children and Teens",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Bipolar Disorder in Children and Teens.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d7.png`,
              },
              {
                name: "Bipolar Disorder in Teens and Young Adults Know the Signs (Infographic)",
                description:
                  "Bipolar Disorder in Teens and Young Adults Know the Signs (Infographic)",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Bipolar Disorder in Teens and Young Adults Know the Signs (Infographic).html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a13a.png`,
              },
              {
                name: "Children and Mental Health Is This Just A Stage",
                description: "Children and Mental Health Is This Just A Stage",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Children and Mental Health Is This Just A Stage.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_aa16.png`,
              },
              {
                name: "Disruptive Mood Dysregulation The Basics",
                description: "Disruptive Mood Dysregulation The Basics",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Disruptive Mood Dysregulation The Basics.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a1.png`,
              },
              {
                name: "Get Excited About The Brain A Coloring _ Activity Book for Kids Ages 8 to 12",
                description:
                  "Get Excited About The Brain A Coloring _ Activity Book for Kids Ages 8 to 12",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Get Excited About The Brain A Coloring _ Activity Book for Kids Ages 8 to 12.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c52.png`,
              },
              {
                name: "Helping Children and Adolescents Cope With Disasters and Other Traumatic Events",
                description:
                  "Helping Children and Adolescents Cope With Disasters and Other Traumatic Events",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Helping Children and Adolescents Cope With Disasters and Other Traumatic Events.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_dd3.png`,
              },
              {
                name: " I'm So Stressed Out! (Infographic)",
                description: " I'm So Stressed Out! (Infographic)",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/I am So Stress Out Infographic.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a7.png`,
              },
              {
                name: " I'm So Stressed Out!",
                description: " I'm So Stressed Out!",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/I am So Stressed Out.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/stressout.png`,
              },
              {
                name: "Let_s Talk About Eating Disorders",
                description: "Let_s Talk About Eating Disorders",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Let_s Talk About Eating Disorders.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_c6.png`,
              },
              {
                name: "Pediatric Autoimmune Neuropsychiatric Disorders (PANDAS) Associated with Streptococcal Infections",
                description:
                  "Pediatric Autoimmune Neuropsychiatric Disorders (PANDAS) Associated with Streptococcal Infections",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Pediatric Autoimmune Neuropsychiatric Disorders (PANDAS) Associated with Streptococcal Infections.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_d10.png`,
              },
              {
                name: "Stand Up To Stress! A Coloring _ Activity Book For Kids Ages 8 to 12",
                description:
                  "Stand Up To Stress! A Coloring _ Activity Book For Kids Ages 8 to 12",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Stand Up To Stress A Coloring _ Activity Book For Kids Ages 8 to 12.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_bb.png`,
              },
              {
                name: "Stress Catcher Catch Some Great Coping Strategies and Skills for Managing Stress",
                description:
                  "Stress Catcher Catch Some Great Coping Strategies and Skills for Managing Stress",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Stress Catcher Catch Some Great Coping Strategies and Skills for Managing Stress.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/stresscatcher.png`,
              },
              {
                name: "Teen Depression More Than Just Moodiness",
                description: "Teen Depression More Than Just Moodiness",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Teen Depression More Than Just Moodiness.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a29.png`,
              },
              {
                name: "The Teen Brain 7 Things To Know",
                description: "The Teen Brain 7 Things To Know",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/The Teen Brain 7 Things To Know.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a43.png`,
              },
              {
                name: "Understanding Psychosis",
                description: "Understanding Psychosis",
                file: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/English/Understanding Psychosis.html`,
                image: `${process.env.REACT_APP_Patient_Documents}/LearningDocs/images/logo_a50.png`,
              },
            ]}
          />
        </Col>
       
      </Row>
    </Container>
  );
}
