import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import logo from "../assets/logoMobilecopy.png";
import { Container, Row, Col } from "react-bootstrap";
import pic from "../assets/blue.png";

export default function CareTeamCard(props) {
  return (
    <Container>
      <Row style={{ marginTop: 30 }}>
        <Col sm={4} xs={6} lg={4} md={4} style={{ marginTop: 20 }}>
          <label
            style={{
              color: "black",
              fontSize: 20,
              fontWeight: "bold",
              marginBottom: 5,
            }}
          >
            {props.lastName}
            {props.firstName}
          </label>
          <Row>
            <label style={{ color: "black", fontSize: 17, marginBottom: 5 }}>
              {props.role}
            </label>
          </Row>
          <Row>
            <label style={{ color: "black", fontSize: 15 }}>
              {props.phoneNo}
            </label>
          </Row>
        </Col>

        <Col sm={2} xs={2} lg={4} md={2}>
          {props.photoUrl !== undefined &&
          props.photoUrl !== null &&
          props.photoUrl.length > 0 ? (
            <img
              //   resizeMode="stretch"
              src={`${process.env.REACT_APP_Documents_URL}/${encodeURI(
                props.photoUrl
              )}`}
               style={{
                width: "120px",
                height: "120px",
                margin: "5px",
                // marginBottom:,
                borderRadius: "100px",
                objectFit: "stretch",
              }}
            />
          ) : (
            <img
              //   resizeMode="stretch"
              src={pic}
               style={{
                width: "120px",
                height: "120px",
                margin: "5px",
                // marginBottom:,
                borderRadius: "100px",
                objectFit: "stretch",
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
