import React from "react";
import ReactDOM from "react-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/logoMobilecopy.png";
import add from "../assets/add.png";
import deletee from "../assets/delete.png";
import Chart from "react-google-charts";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { BiPlusMedical } from "react-icons/bi";
const history = createBrowserHistory({ forceRefresh: true });
export default function Journal() {
  const headers = {
    Authorization: `Bearer  ${localStorage.getItem("dtt_PatientToken")}`,
  };

  const patientId = `${localStorage.getItem("dtt_PatientId")}`;
  const state = {
    // HeadTable: ['name', 'date', 'type', 'Notes'],
    DataTable: [],
  };

  const navigation = useNavigate();
  const [text, onChangeText] = React.useState(null);
  const [date, setDate] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [token, setToken] = useState(
    `${localStorage.getItem("dtt_PatientToken")}`
  );
  const [rows, setRows] = useState("");
  // const _renderItem = (data, i) => (
  //   // <div style={[{ backgroundColor: data }, styles.item]} key={i} />
  // )

  useEffect(() => {
    if (patientId > 0) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/PatientJournal/PatientJournals?patientid=${parseInt(patientId)}`,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => setRows(response.data))
        .catch((error) => {
          console.warn("There was an error!", error);
        });
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [patientId, refresh]);
  const [editId, setEditId] = useState("");

  const Item = ({ item, index }) => {
    return (
      <>
        <Col xs={3} sm={3} md={2} lg={1} style={{ paddingLeft: "0px" }}>
          <label
            style={{ padding: 10, fontWeight: "bold" }}
            onClick={
              () =>
                navigation("../JournalRegistration", {
                  state: { id: item.id, list:rows, index:index },
                })

              // navigation.navigate('JournalRegistration',{id:item.id})
            }
          >
            {item.date}
          </label>
        </Col>
        <Col xs={7} sm={7} md={7} lg={8}>
          <label
            style={{ padding: 10, paddingLeft: "15px",width:'100%',  overflow: "auto",  flexWrap:"wrap",
            wordWrap: "break-word" }}
            onClick={
              () =>
                navigation("../JournalRegistration", {
                  state: { id: item.id, list:rows, index:index  },
                })

              // navigation.reset({
              //   routes: [{ name: "JournalRegistration", id: item.id }],
              // })

              // navigation.navigate('JournalRegistration',{id:item.id})
            }
          >
            {item.comment}
          </label>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} style={{ padding: 10 }}>
          {/* <img
            onClick={() =>
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/PatientJournal/AddPatientJournal`,
                  {
                    id: item.id,
                    inactive: true,
                    archive: item.archive,
                    clientCreatedDate: item.clientCreatedDate,
                    clientUpdatedDate: item.clientUpdatedDate,
                    comment: item.comment,
                    createdBy: item.createdBy,
                    createdDate: item.createdDate,
                    date: item.date,
                    name: item.name,
                    notes: item.notes,
                    patientId: item.patientId,
                    type: item.type,
                    updatedBy: item.updatedBy,
                    updatedDate: item.updatedDate,
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  if (response.data) {
                    setRefresh(!refresh);
                  } else {
                    alert(`Opps!! Something went Wrong.... Try again`);
                  }
                })
            }
            src={deletee}
            style={{ width: 40, height: 40, marginBottom: "10px" }}
            alt="Delete"
          /> */}
        </Col>
        <Col xs={1} sm={1} md={2} lg={2}></Col>
        {/*  <Button
            style={styles.button1}
            
          >
            Delete
          </Button> */}
      </>
    );
  };
  function Home() {
    navigation("../Home");
  }
  return (
    <Container
      style={{ alignContent: "center", alignItems: "center", maxWidth: "100%" }}
    >
      <Row>
        <Col className="text-center">
          <label
            style={{
              padding: "10px",
              fontWeight: "bold",
              fontSize: "30px",
              color: "#31A5D7",
            }}
          >
            My Personal Journal{" "}
          </label>
        </Col>
      </Row>
      <Row>
        {/* <Col sm={4}></Col> */}
        <Col className="text-center">
          <Button
            className="button"
            type="submit"
            style={{
              borderRadius: 5,
              marginTop: 10,
              color: "black",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 10,
              backgroundColor: "#FCBE13",
            }}
            onClick={() => navigation("../JournalRegistration")}
          >
            <BiPlusMedical style={{ marginBottom: "4px" }} />
            Add a New Journal Entry
          </Button>
          {/* <img
          onClick={() =>
            navigation("../JournalRegistration", 
             
            )
          }
            src={add}
            style={{ width: 70, height: 70, marginBottom: 10 }}
            alt="Add"
          /> */}
        </Col>
        {/* <Col sm={4}></Col> */}
      </Row>
      <Row style={{ paddingLeft: "0px" }}>
        {rows != null && rows.length > 0
          ? rows.map((row, i) => <Item item={row} index={i}/>)
          : "No Record Found"}
      </Row>
    </Container>
  );
}
