import React from "react";
import ReactDOM from "react-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../assets/logoMobilecopy.png";
import link from "../assets/link.png";
import pic from "../assets/blue.png";
import Chart from "react-google-charts";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { createBrowserHistory } from "history";
import CareTeamCard from "../components/CareTeamCard";
import { BiPlusMedical } from "react-icons/bi";
import CircularProgress from "@mui/material/CircularProgress";
// const patientId = `${AsyncStorage.getItem('dtt_user_id')}`

const CareTeam = ({ navigation }) => {
  const [token, setToken] = useState(
    `${localStorage.getItem("dtt_PatientToken")}`
  );
  const patientId = `${localStorage.getItem("dtt_PatientId")}`;

  const headers = {
    Authorization: `Bearer  ${localStorage.getItem("dtt_PatientToken")}`,
  };
  const [careTeamMembers, setCareTeamMembers] = useState([]);
  const [RoomLink, setRoomLink] = useState('');
  const [showButton, setshowButton] = useState(false);
  const [handleopen, sethandleopen] = useState(false);
  const [submitting, setsubmitting] = useState(false);

  console.log("Roomlink value : ", RoomLink)


  console.log("CAre dataaa is ", careTeamMembers);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    setsubmitting(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }/CareTeamMembers/getPatientCareTeamMembers?patientId=${parseInt(
          patientId
        )}`,

        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {  setsubmitting(false)
        setCareTeamMembers(response.data);
        console.log("DAtaaaaaaaaaaaaaaa", response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [patientId]);

  useEffect(() => {
    setsubmitting(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }/PatientPortal/getPatientRoomNameByPatientId?patientId=${parseInt(
          patientId
        )}`,

        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setsubmitting(false)
        if (response.data == 'Meeting is not Created against that Patient') {
          console.log("No Room id Found Against this")
          // setCareTeamMembers(response.data);
          console.log("DAtaaaaaaaaaaaaaaa", response.data);
        }
        else if (response.data == undefined || response.data == null) {
          console.log("No Patient Found Against this")
        }
        else {
          setRoomLink(response.data);
          console.log("Join meeting room")
          console.log("Join meeting room Link", response.data)
          setshowButton(true)
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

  }, [patientId]);


  const handleJoinRoom = () => {
    sethandleopen(true)
    window.open(RoomLink, '_blank');
  };

  const Item = ({ item }) => {
    return (
      <Row>
        <Col xs={0} sm={0} md={0} lg={1}></Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <CareTeamCard
            photoUrl={item.photoUrl}
            firstName={`${item.lastName}, ${item.firstName} `}
            memberName={`${item.memberName}`}
            role={`${item.role}`}
            phoneNo={`${item.phoneNo}`}
          />
        </Col>
        <Col xs={0} sm={0} md={0} lg={1}></Col>
      </Row>
    );
  };

  return (<>    
  {submitting?(
        <CircularProgress
          style={{
            width: "50px",
      marginTop:'7rem',

            position: "absolute",
            left: "50%",
       
            marginLeft: "-25px",
      
            // zIndex: 100000,
            color: "#FCBE13",
          }}
        />
      ):(   <Container>
        <Row>
          {/* <BackButton goBack={navigation.goBack} /> */}
          {/* <Logo /> */}
          {/* <FlatList
              data={careTeamMembers}
              renderItem={({ item }) => <Item item={item} />}
              keyExtractor={(item) => item.id}
            /> */}
          <Col sm={8}>
            {careTeamMembers != null && careTeamMembers.length > 0
              ? careTeamMembers.map((row, index) => <Item item={row} />)
              : "No Record Found"}
          </Col>
  
  
          {showButton ? (
            <Col sm={4}>
              {/* <Col className="text-center"> */}
              <Button
                className="button"
                type="submit"
                style={{
                  borderRadius: 5,
                  marginTop: 20,
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 10,
                  backgroundColor: "#FCBE13",
                }}
                onClick={handleJoinRoom}
              >
                <BiPlusMedical style={{ marginBottom: "4px", marginleft: '2px' }} />
                Join Call
              </Button>
  
            </Col>
          ) : null
          }
        </Row>
  
  
        {handleopen ? (
          <div
            id="iframe_container"
            style={{
              position: 'absolute',
              top: '3%',
              // top: '15%',
              bottom: '0%',
              left: '0%',
              right: '0%',
              marginTop: '50px',
  
              // marginBottom:"10px",
            }}
  
  
          >
            <iframe
              name="ifr"
              // scrolling="yes"
              fullWidth
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
              }}
              src={`${RoomLink}`}
              frameborder="0"
              allow="microphone; camera"
              allowfullscreen
            ></iframe>
          </div>
        ) : null
        }
  
  
      </Container>)}
 
    </>

  );
};
export default CareTeam;
